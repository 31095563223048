import useGet from "api/useGet";
import React, {useEffect, useState} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {ContentsSection, PageBody, PageSubHeader} from "component/app/items";
import { BtnItem } from "component/basic/btns";
import { Header } from "component/elements/header";
import moment from "moment";
import 'moment/locale/ko';
import {comFormat} from "../../js/function";
import {BottomErrMsg} from "../../component/basic/popup";
const TempWorker = (props) => {
    const navigate = useNavigate();
    const location = useLocation().search;

    const pram = location.split('&') ? location.split('&') : [];

    const paramMessage = pram[0]? decodeURIComponent(pram[0].split('?message=')[1]) : '';

    const [month, setMonth] =  useState(moment().format("YYYY.MM"));
    const [tab, setTab] =  useState("");
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    const tempWorkerApi = useGet({url:`/temp/worker?company_id=${localStorage.getItem("companyId")}&begin_month=${moment(month, "YYYY.MM").format("YYYY-MM")}&tab=${tab}`, loginType:"login"});
    const tempWorkers = tempWorkerApi?.data?.temp_workers;

    useEffect(() => {
        if (paramMessage) {
            setBottomMsgData({
                text : paramMessage,
                chk : bottomMsgData.chk + 1
            });

            setTimeout(() => {
                navigate(`/tempWorker`);
            }, 100);
        }
    }, [paramMessage]);


    function handleResize(){
        var totalSize = 0;
        let headerSize = document.querySelectorAll(".header")[0].clientHeight,
            subTitleSize = document.querySelectorAll(".pageSubHeaderBox")[0].clientHeight,
            selectYearBoxSize = document.querySelectorAll(".selectYearBox")[0].clientHeight,
            menuSize = document.querySelectorAll(".menu")[0].clientHeight,
            pageBtnSize = document.querySelectorAll(".pageBtn")[0].clientHeight,
            viewSize = document.documentElement.clientHeight;

        totalSize = viewSize - (headerSize + subTitleSize + menuSize + pageBtnSize + 2)

        document.querySelectorAll(".pageBody")[0].style.height = `${totalSize}px`;
        document.querySelectorAll(".cardBox")[0].style.height = `${totalSize - selectYearBoxSize}px`;
    }

    useEffect(() => {
        handleResize()
    }, [tempWorkers]);

    return (
        <>
            <Header
                addClass="pageMainBg"
                menuBtn={true}
                headTitle={tempWorkerApi?.data?.company_name}
                centerTitle={true}
            />
            <ContentsSection addClass="headerContents pageMainBg">
                <PageSubHeader
                    addCardClass="subHeaderCard"
                    icon={<img className="icon" src="/assets/images/icon/tempWorker.svg"/>}
                    contents="일시근무자"
                    menu={<div className="menu">
                        <div className={`menuButton ${tab === '' ? 'active ' : ''}`} onClick={() => setTab('')}>전체 {tempWorkerApi?.data?.all_count}</div>
                        <div className={`menuButton ${tab === '기타소득' ? 'active ' : ''}`} onClick={() => setTab('기타소득')}>기타소득 {tempWorkerApi?.data?.etc_count}</div>
                        <div className={`menuButton ${tab === '사업소득' ? 'active ' : ''}`} onClick={() => setTab('사업소득')}>사업소득 {tempWorkerApi?.data?.business_count}</div>
                        <div className={`menuButton ${tab === '일용직 근무' ? 'active ' : ''}`} onClick={() => setTab('일용직 근무')}>일용직 근무 {tempWorkerApi?.data?.daily_use_count}</div>
                    </div>}
                />
                <PageBody
                    useMonth={true}
                    month={month}
                    setMonth={setMonth}>
                    <div className="cardBox">
                        {tempWorkers?.map((tempWorker) => (
                            <div className="card" onClick={() => navigate(`/tempWorker/modify/${tempWorker?.id}`)}>
                                <div className="cardTitleBox">
                                    <div className="title tag">
                                        {tempWorker?.type === '기타소득' && (<div className="categoryTag greenTag m-0">기타소득</div>)}
                                        {tempWorker?.type === '사업소득' && (<div className="categoryTag blueTag m-0">사업소득</div>)}
                                        {tempWorker?.type === '일용직 근무' && (<div className="categoryTag yellowTag m-0">일용직 근무</div>)}

                                        <div className="name">{tempWorker?.name}</div>
                                    </div>
                                </div>
                                <div className="cardContentsBox">
                                    {tempWorker?.issues?.map((issue) => (
                                        <div className="largeItem">
                                            <div className="contents space">
                                                <div className="title">지급 금액</div>
                                                <div className="text">{comFormat(issue?.amount)}원 ({issue?.amount_type === 'after' ? '세후' : (issue?.amount_type === 'before' ? '세전' : '')})</div>
                                            </div>
                                            <div className="contents space">
                                                <div className="title">지급 일자</div>
                                                <div className="text">{moment(issue?.date).format('YYYY.MM.DD')} ({moment(issue?.date).format('dddd').replace('요일', '')})</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </PageBody>
                <BtnItem
                    addClass=""
                    contents={"일시 근무자 등록"}
                    disabled={false}
                    func={() => {navigate("/tempWorker/register")}}
                />
            </ContentsSection>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
        </>
    );
};

export default TempWorker;