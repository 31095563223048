import moment from 'moment';
import React from "react";

function PageSizing(data){
    return (
        <div className={`pageSizing ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    );
}

function ContentsSection(data){
    return (
        <div className={`contentsSection ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    );
}

function ItemSection(data){
    return (
        <div className={`itemSection ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    );
}

function ItemSectionTitle(data){
    return (
        <div className="itemSectionTitleBox">
            <h1 className="itemSectionTitle" dangerouslySetInnerHTML={{__html:data.contents}}/>
            {data.subTitle ? <p className="itemSectionSubTitle" dangerouslySetInnerHTML={{__html:data.subTitle}}/> : ""}
        </div>
    );
}

function TextItem(data){
    return (
        <div className={`textItem ${data.addClass ? data.addClass : ""}`}>
            <p className="textItem_title" dangerouslySetInnerHTML={{__html:data.title}}/>
            <p className="textItem_contents" dangerouslySetInnerHTML={{__html:data.contents}}/>
        </div>
    );
}

function PageTitle(data){
    return (
        <div className="pageTitleBox">
            <h1 className="pageTitle" dangerouslySetInnerHTML={{__html:data.contents}}/>
            {data.subTitle ? <p className="pageSubTitle" dangerouslySetInnerHTML={{__html:data.subTitle}}/> : ""}
            {data.subTitleBtn ? <button type="button" onClick={()=>{data.subTitleBtnFunc()}} className="pageSubTitleBtn" dangerouslySetInnerHTML={{__html:data.subTitleBtn}}/> : ""}
        </div>
    );
}

function PageSubHeader(data){
    return (
        <>
            <div className="pageSubHeaderBox">
                {data?.icon && (data?.icon)}
                <div className="pageSubHeaderTitle">
                    { data?.contents }
                </div>
            </div>
            {data?.menu && (data?.menu)}
            <div className={`${data?.title2 ? 'subHeaderCardBox ' : ''}`}>
                {data?.title && (<div className={`card ${data?.addCardClass ? data?.addCardClass : ''} ${data?.title2 ? 'col2 ' : ''}`}>
                    <div className="cardTitleBox">
                        <div className="titleLabel">{data?.title}</div>
                    </div>
                    {data?.subTitle && <div className="subTitleLabel">{data?.subTitle}</div>}
                </div>)}
                {data?.title2 && (
                    <div className={`card col2 ${data?.addCardClass ? data?.addCardClass : ''}`}>
                        <div className="cardTitleBox">
                            <div className="titleLabel">{data?.title2}</div>
                        </div>
                        {data?.subTitle2 && <div className="subTitleLabel">{data?.subTitle2}</div>}
                    </div>
                )}
            </div>
        </>
    );
}

function PageBody(data){
    return (
        <div className={`pageBody ${data?.addClass ? data?.addClass : ''}`}>
            {data?.useYear && <div className="selectYearBox">
                <img className="beforeAfterButton" src="/assets/images/basic/before_button.svg" onClick={() => data?.setYear(parseInt(data?.year) - 1)}/>
                <div className="selectYearText">{data?.year}</div>
                <img className="beforeAfterButton" src="/assets/images/basic/after_button.svg" onClick={() => data?.setYear(parseInt(data?.year) + 1)}/>
            </div>}
            {data?.useMonth && <div className="selectYearBox">
                <img className="beforeAfterButton" src="/assets/images/basic/before_button.svg" onClick={() => data?.setMonth(moment(data?.month, "YYYY.MM", true).subtract(1, 'months').format('YYYY.MM'))}/>
                <div className="selectYearText">{data?.month}</div>
                <img className="beforeAfterButton" src="/assets/images/basic/after_button.svg" onClick={() => data?.setMonth(moment(data?.month, "YYYY.MM", true).add(1, 'months').format('YYYY.MM'))}/>
            </div>}
            {data?.useWeek && (
                <>
                    <div className="weekTitle">
                        {data?.week[0]?.y}. {data?.week[0]?.m}
                    </div>
                    <div className="selectYearBox">
                        <img className="beforeAfterButton" src="/assets/images/basic/before_button.svg" onClick={() => { data?.setDate(moment(data?.week[0]?.fullDate)?.subtract(7, 'days').format('YYYY-MM-DD')); }}/>
                        <div className="weekBox">
                            {data?.week?.map((week_) => (
                                <div className={`weekItem ${data?.selDate ? data?.selDate === week_?.fullDate ? 'active' : '' : week_?.fullDate === moment().format('YYYY-MM-DD') ? 'active' : ''}`} onClick={()=>{if(data?.dateClick)data?.dateClick(week_?.fullDate)}}>
                                    <div className="weekItemDayOfWeek">{week_?.weekDay}</div>
                                    <div className="weekItemDay">{parseInt(week_?.d)}</div>
                                    {week_?.fullDate === moment().format('YYYY-MM-DD') && (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                                            <circle cx="2" cy="2" r="2" fill="#0094FF"/>
                                        </svg>
                                    )}
                                </div>
                            ))}
                        </div>
                        <img className="beforeAfterButton" src="/assets/images/basic/after_button.svg" onClick={() => { data?.setDate(moment(data?.week[0]?.fullDate)?.add(7, 'days').format('YYYY-MM-DD')); }}/>
                    </div>
                </>
            )}
            {data.children}
        </div>
    );
}

function BoardCard({ data }){
    return (data?.map((board) => (
        <div key={board?.id} className="card" onClick={()=>{ window.location.href = `/board/detail/${board?.id}` }}>
            <div className="boardTitleBox">
                {(board?.category && board?.category !== '일반') && (<div className={`categoryTag ${board?.category === '공지' ? 'blueTag' : (board?.category === '법정의무교육' ? 'redTag' : '')}`}>{board?.category}</div> )}
                <div className="boardTitle">{board?.title}</div>
                <div className="boardDate">{board?.created_at.split(' ')[0]}</div>
            </div>
            <div className="boardContents" dangerouslySetInnerHTML={{ __html: board?.contents }} />
        </div>
    )));
}

function InputSection(data){
    return (
        <div className={`input_section ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    );
}

function LogoBox(data){
    return (
        <div className={`logoBox ${data.addClass ? data.addClass : ""}`}>
            <img src="/assets/images/app/logo.svg"/>
        </div>
    );
}

function InfoItem(data){
    return (
        <p className={`infoItem ${data.addClass ? data.addClass : ""}`} dangerouslySetInnerHTML={{__html:data.contents}}/>
    );
}

function CaptionItem(data){
    return (
        <p className={`captionItem ${data.addClass ? data.addClass : ""}`} dangerouslySetInnerHTML={{__html:data.contents}}/>
    );
}

function TimeSelBox(data){
    return (
        <div className={`timeSelBox ${data.addClass||""}`}>
            {data?.data?.map((item,i)=>(
                <button type="button" onClick={()=>{data.func(item.time)}} key={i} className={`timeSelItem ${data.value == item.time ? "active" : ""}`} disabled={item.is_reservation == 1 ? true : false}>{item.time}</button>
            ))}
        </div>
    );
}

function EditerBox(data){

    return (
        <div className="editerBox">
            {data.children}
        </div>
    );
}

function InfoTextBox(data){

    return (
        <div className="infoTextBox">
            <h1 className="infoText_title" dangerouslySetInnerHTML={{__html:data.title}}/>
            <p className="infoText" dangerouslySetInnerHTML={{__html:data.text}}/>
        </div>
    );
}

function InputViewBox(data){

    return (
        <div className={`inputViewBox ${data.type == "textArea" ? "textAreaViewBox" : ""}`}>
            <p className="inputViewText" dangerouslySetInnerHTML={{__html:data.text}}/>
        </div>
    );
}

function FileViewBox(data){

    return (
        <div className={`fileViewBox`}>
            {data.data.map((item,i)=>(
                <div className="backgroundImg" key={i} style={{backgroundImage:`url('${item.image_url}')`}}/>
            ))}
        </div>
    );
}

function NameTextItemBox(data){

    return (
        <div className={`nameTextItemBox ${data.addClass||""}`}>
            {data.children}
        </div>
    );
}

function NameTextItem(data){

    return (
        <div className={`nameTextItem ${data.addClass||""}`}>
            <p className="nameTextItem_name" dangerouslySetInnerHTML={{__html:data.name}}/>
            <p className="nameTextItem_text" dangerouslySetInnerHTML={{__html:data.text}}/>
        </div>
    );
}

function LinkItem(data){

    return (
        <div className={`linkItemBox ${data.addClass||""}`}>
            {data.data && data.data.map((item,i)=>(
                <button type="button" key={i} className="linkItem" onClick={()=>{item.func()}}>
                    {item.icon ? <img src={item.icon}/> : ""}
                    <p className="linkItem_name" dangerouslySetInnerHTML={{__html:item.name}}/>
                </button>
            ))}
        </div>
    );
}

export {PageSizing,ContentsSection,ItemSection,ItemSectionTitle,TextItem,LogoBox,PageTitle,PageSubHeader,BoardCard,PageBody,InputSection,InfoItem,CaptionItem,TimeSelBox,EditerBox,InfoTextBox,InputViewBox,FileViewBox,NameTextItemBox,NameTextItem,LinkItem};