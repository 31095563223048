import useGet from "api/useGet";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {ContentsSection, PageBody, PageSubHeader} from "component/app/items";
import { BtnBox, BtnItem } from "component/basic/btns";
import { Header } from "component/elements/header";
import * as fatchSet from "../../api/api";
import SignaturePad from "react-signature-canvas";
import { DocumentCard } from "component/document/items";
import { BottomErrMsg, ImagePopup, ToastBasicContents, ToastPopup } from "component/basic/popup";
import { BottomPopup } from "component/basic/formItems";

const DocumentSign = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const id = param.id;

    const signRef = useRef(null);
    
    const [imagePopupData, setImagePopupData] =  useState(null);
    const [toastPopupData,setToastPopupData] =  useState(null);
    const [completePopup, setCompletePopup] =  useState(false);
    const [signData,setSignData] =  useState([]);
    const [signChk,setSignChk] =  useState(false);
    const [signPage,setSignPage] =  useState(1);
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});
    const [loading,setLoading] =  useState(false);

    const documentApi = useGet({url:`/document/detail?company_id=${localStorage.getItem("companyId")}&document_id=${id}`, loginType:"login"});
    const documentData = documentApi?.data?.document;

    function handleResize(){
        var totalSize = 0;
        let headerSize = document.querySelectorAll(".header")[0].clientHeight,
            detailCardSize = document.querySelectorAll(".documentDetail_cardArea")[0].clientHeight,
            pageBtnSize = 55,
            viewSize = document.documentElement.clientHeight;

        totalSize = viewSize - (headerSize + detailCardSize + pageBtnSize + 2)

        document.querySelectorAll(".pageBody")[0].style.height = `${totalSize}px`;
        document.querySelectorAll(".documentDetail_section")[0].style.height = `${totalSize}px`;
    }

    function signDataSetting(id){
        let dataURL = signRef?.current?.toDataURL("image/png");
        let singDataSet = [...signData];
        let signNewData = {
            sign:dataURL,
            id:id
        }
        singDataSet.push(signNewData);
        setSignData(singDataSet);
        setTimeout(() => {
            signRef?.current?.clear();
        }, 0);
    }

    function signOpen(){
        setSignData([]);
        setToastPopupData({
            addClass:"",
            title:null,
            closeType:true,
            closeFunc:(e) => {setSignData([]);setTimeout(() => {setToastPopupData(null)}, 200)},
            closeFuncChk:true,
            btn0:null,
            btn0Type:"",
            btn1:null,
            btn1Type:"",
            btnFunc0:(e)=>{},
            btnFunc1:(e)=>{},
        });
    }

    function saveSign(){
        setLoading(true)
        let formData = new FormData();
        formData.append("company_id", localStorage.getItem("companyId"));
        formData.append("document_id", id);

        signData?.forEach(function(item,i){
            formData.append("sign_id[]", item?.id);
            formData.append("sign[]", item?.sign);
        });

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: "/document/sign",
            loginType: "login",
            success: (data) => {
                setLoading(false)
                setSignData([]);
                setTimeout(() => {
                    setToastPopupData(null)
                }, 200);
                setTimeout(() => {
                    setCompletePopup(true);
                }, 400);
            },
            err: (data) => {
                setLoading(false)
                if(data.data || data.alert){
                    setBottomMsgData({
                        text : data.alert||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    }

    useEffect(() => {
        if(signData?.length > 0){
            if(signPage < documentApi?.data?.document_signs?.length){
                setSignPage(signPage + 1);
            }else if(signPage === documentApi?.data?.document_signs?.length){
                saveSign();
            }
        }
    }, [signData]);

    useEffect(() => {
        handleResize();
    }, [documentData]);

    return (
        <>
            <Header
                addClass="pageMainBg"
                pageBack={true}
                func={() => {
                    navigate(-1)
                }}
                headTitle="문서 서명하기"
                centerTitle={true}
            />
            <ContentsSection addClass="headerContents pageMainBg">
                <div className="documentDetail_cardArea">
                    <DocumentCard
                        func={()=>{}}
                        type={documentData?.type}
                        title={documentData?.title}
                        created_at={documentData?.created_at}
                        status={documentData?.status === 2 ? documentData?.status : null}
                    />
                </div>
                <PageBody>
                    <div className="documentDetail_section">
                        <div className="signImg_viewBox">
                            <img src={documentData?.image_url}/>
                            {/* :""} */}
                            <button type="button" className="btn_image_view" onClick={()=>{
                                if (documentData?.status === 1) {
                                    setImagePopupData({
                                        addClass:"",
                                        closeType:null,
                                        closeFunc:()=>{setImagePopupData(null)},
                                        img:documentData?.image_url,
                                        btn0: "서명하기",
                                        btnFunc0:()=>{
                                            setImagePopupData(null);
                                            setTimeout(() => {
                                                signOpen();
                                            }, 200);
                                        }
                                    })
                                } else {
                                    setImagePopupData({
                                        addClass:"",
                                        closeType:null,
                                        closeFunc:()=>{setImagePopupData(null)},
                                        img:documentData?.image_url,
                                    })
                                }

                            }}><img src="/assets/images/icon/img_view_icon.svg"/></button>
                        </div>
                    </div>
                </PageBody>
                {documentData?.status === 1 ? 
                    <BtnBox
                        boxType="fixed"
                        addClass=""
                    >
                        <BtnItem
                            addClass=""
                            contents={"서명하기"}
                            disabled={false}
                            func={() => {signOpen()}}
                        />
                    </BtnBox>
                :""}
                {documentData?.status === 2 ?
                    <BtnBox
                        boxType="fixed"
                        addClass=""
                    >
                        <BtnItem
                            addClass=""
                            btnType="link"
                            contents={"문서 다운로드"}
                            disabled={false}
                            btnLink={`${process.env.REACT_APP_API_URL}/file/download?url=${documentData?.pdf_url}`}
                            download={true}
                            func={() => {}}
                        />
                    </BtnBox>
                :""}
            </ContentsSection>
            <ImagePopup
                data={imagePopupData}
            />
            <ToastPopup data={toastPopupData} closeType={true}>
                <ToastBasicContents
                    title={"서명하기"}
                    text={"아래 서명패드에 직접 서명이 필요해요."}
                    subItem={`<span>${signPage}</span> / ${documentApi?.data?.document_signs?.length}`}
                />
                <div className="signArea">
                    <button type="button" onClick={()=>{
                        let signDataSet = [...signData];
                        let signDataDel = signDataSet?.filter((el)=> el?.id !== documentApi?.data?.document_signs[signPage - 1]?.id);
                        setSignData(signDataDel);
                        signRef?.current?.clear();
                        setSignChk(false);
                    }} className="signClear">지우기</button>
                    <SignaturePad
                        ref={signRef}
                        clearOnResize={false}
                        canvasProps={{width: window.innerWidth - 40, height: 120, className: "signCanvas"}}
                        onEnd={() => {setSignChk(true)}}
                    />
                </div>
                <BtnBox
                    boxType="fixed"
                    addClass="col2"
                >
                    <BtnItem
                        addClass=""
                        contents={"취소"}
                        disabled={false}
                        func={() => {
                            setSignData([]);
                            setTimeout(() => {
                                setToastPopupData(null)
                            }, 200);
                        }}
                    />
                    <BtnItem
                        addClass=""
                        contents={loading ? '서명 진행중···' : (signPage === documentApi?.data?.document_signs?.length ? "서명완료" : "다음 서명하기")}
                        disabled={signChk ? false : true}
                        func={() => {
                            signDataSetting(documentApi?.data?.document_signs[signPage - 1]?.id);
                            setSignChk(false);
                        }}
                    />
                </BtnBox>
            </ToastPopup>
            <BottomPopup
                open={completePopup}
                title="서명 완료"
                subTitle={<div>전자 서명이 완료되었습니다.</div>}
                btn1Text="확인"
                btn1Func={() => {setCompletePopup(false);window.location.reload(true)}}
            />
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
        </>
    );
};

export default DocumentSign;