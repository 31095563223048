import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Main = (props) => {
    const navigate = useNavigate();

    const companyApi = useGet({
        url:"/company",
        loginType:"login"
    });
    const companyData = companyApi?.data?.companies;

    useEffect(() => {
        if(localStorage.getItem("token")){
            if (companyData) {
                if(companyData?.length > 1){
                    navigate("/member/company");
                }else{
                    localStorage.setItem("companyId",companyData[0]?.id);
                    navigate("/workHistory");
                }
            }
        }else{
            navigate("/member/login");
        }
    }, [companyData]);

    return (
        <></>
    );
};

export default Main;