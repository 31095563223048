import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BottomErrMsg, Popup } from "component/basic/popup";
import { ContentsSection, InputSection, PageTitle } from "component/app/items";
import { InputItemBox } from "component/basic/formItems";
import { BtnBox, BtnItem } from "component/basic/btns";
import { CompanyBtn, SnsBtn } from "component/member/items";
import { Header } from "component/elements/header";
import { NotItems } from "component/basic/notItems";

const PwSetting = (props) => {
    const navigate = useNavigate();

    const [pw,setPw] =  useState("");
    const [pwCom,setPwCom] =  useState("");

    const [btnChk,setBtnChk] =  useState(true);
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});
    const [inputErr,setInputErr] =  useState(false);

    function pwSetting(){
        let formData = new FormData();
        formData.append("password", pw);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: "/password/set",
            loginType: "login",
            success: (data) => {
                if(data.data.company_count === 1){
                    localStorage.setItem("companyId",data.data.company_id);
                    navigate("/workHistory");
                }else{
                    navigate(`/member/company`);
                }
            },
            err: (data) => {
                if(data.data || data.alert){
                    setInputErr(true);
                    setBottomMsgData({
                        text : data.alert||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    }

    useEffect(() => {
        let pwType = /^(?=.*[a-zA-Z])(?=.*[0-9]).{6,}$/;

        setInputErr(false);

        if(pwType.test(pw) && pw !== "" && pwType.test(pwCom) && pwCom !== "" && pw === pwCom){
            setBtnChk(false);
        }else{
            setBtnChk(true);
        }
    }, [pw,pwCom]);

    return (
        <>
            <Header
                pageBack={true}
                func={() => {
                    window.logOut();
                    navigate(`/member/login`);
                }}
                headTitle="비밀번호 설정"
                centerTitle={true}
            />
            <ContentsSection addClass="headerContents btnContents">
                <PageTitle
                    contents="웨이브원 접속을 위해<br/>비밀번호를 등록해주세요"
                />
                <InputSection>
                    <InputItemBox
                        inputType="password"
                        addClass={inputErr ? "err" : ""}
                        inputName="비밀번호"
                        placeholder="숫자+영문 포함 6자리 이상 입력"
                        pwChType={true}
                        value={pw}
                        max={null}
                        regexp={null}
                        func={(value)=>{setPw(value)}}
                    />
                    <InputItemBox
                        inputType="password"
                        addClass={inputErr ? "err" : ""}
                        inputName="비밀번호 확인"
                        placeholder="비밀번호 확인"
                        pwChType={true}
                        value={pwCom}
                        max={null}
                        regexp={null}
                        func={(value)=>{setPwCom(value)}}
                    />
                </InputSection>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        contents={"등록하기"}
                        disabled={btnChk}
                        func={() => {pwSetting()}}
                    />
                </BtnBox>
            </ContentsSection>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
        </>
    );
};

export default PwSetting;