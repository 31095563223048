import useGet from "api/useGet";
import React from "react";
import {ContentsSection, PageBody} from "component/app/items";
import { Header } from "component/elements/header";
import {useNavigate, useParams} from "react-router-dom";
import {comFormat} from "../../js/function";
import moment from "moment";

const SalaryDetail = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const id = param.id

    const salaryApi = useGet({url:`/salary/detail?company_id=${localStorage.getItem("companyId")}&salary_id=${id}`, loginType:"login"});
    const salaryData = salaryApi?.data?.salary;

    return (
        <>
            <Header
                pageBack={true}
                func={() => {navigate(-1)}}
                addClass="pageMainBg"
                headTitle={moment(salaryData?.issue_date).format("YYYY년 M월") + " 급여 명세서"}
                centerTitle={true}
            />
            <ContentsSection addClass="headerContents pageMainBg">
                <div className={`card`}>
                    <div className="cardTitleBox">
                        <div className="titleLabel">최종 수령액</div>
                    </div>
                    <div className="subTitleLabel blueTextColor">{salaryData?.total_issue_pay - salaryData?.total_deduct_pay ? comFormat(salaryData?.total_issue_pay - salaryData?.total_deduct_pay) : 0}원</div>
                </div>
                <PageBody useYear={false} addClass="scroll">
                    <div className="receipt-box">
                        {salaryApi?.data?.company_plan === 'premium' && (
                            <>
                                <div className="receipt-item title">
                                    <div className="label">지급 총액</div>
                                    <div className="contents">{salaryData?.total_issue_pay ? comFormat(salaryData?.total_issue_pay) : 0}원</div>
                                </div>
                                <div className="hr grayHr"/>
                                {salaryData?.default_salary ? (
                                    <div className="receipt-item">
                                        <div className="label">기본급</div>
                                        <div className="contents">{comFormat(salaryData?.default_salary)}원</div>
                                    </div>
                                ) : ''}
                                {salaryData?.tax_free ? (
                                    <div className="receipt-item">
                                        <div className="label">비과세</div>
                                        <div className="contents">{salaryData?.tax_free ? comFormat(salaryData?.tax_free) : 0}원</div>
                                    </div>
                                ) : ''}
                                {salaryData?.inclusive_wage_overtime_price ? (
                                    <div className="receipt-item">
                                        <div className="label">포괄연장수당</div>
                                        <div className="contents">{salaryData?.inclusive_wage_overtime_price ? comFormat(salaryData?.inclusive_wage_overtime_price) : 0}원</div>
                                    </div>
                                ) : ''}
                                {salaryData?.inclusive_wage_holiday_price ? (
                                    <div className="receipt-item">
                                        <div className="label">포괄휴일수당</div>
                                        <div className="contents">{salaryData?.inclusive_wage_holiday_price ? comFormat(salaryData?.inclusive_wage_holiday_price) : 0}원</div>
                                    </div>
                                ) : ''}
                                {salaryData?.inclusive_wage_day_off_price ? (
                                    <div className="receipt-item">
                                        <div className="label">연차수당</div>
                                        <div className="contents">{salaryData?.inclusive_wage_day_off_price ? comFormat(salaryData?.inclusive_wage_day_off_price) : 0}원</div>
                                    </div>
                                ) : ''}
                                {salaryData?.additional_pay ? (
                                    <div className="receipt-item">
                                        <div className="label">추가 수당</div>
                                        <div className="contents">{salaryData?.additional_pay ? comFormat(salaryData?.additional_pay) : 0}원</div>
                                    </div>
                                ) : ''}
                                {salaryData?.holiday_pay ? (
                                    <div className="receipt-item">
                                        <div className="label">휴일 수당</div>
                                        <div className="contents">{salaryData?.holiday_pay ? comFormat(salaryData?.holiday_pay) : 0}원</div>
                                    </div>
                                ) : ''}
                                {salaryData?.extinction_day_off_pay ? (
                                    <div className="receipt-item">
                                        <div className="label">미사용 연차수당</div>
                                        <div className="contents">{salaryData?.extinction_day_off_pay ? comFormat(salaryData?.extinction_day_off_pay) : 0}원</div>
                                    </div>
                                ) : ''}
                                {salaryData?.add_etc ? (
                                    <div className="receipt-item">
                                        <div className="label">직접입력</div>
                                        <div className="contents">{salaryData?.add_etc ? comFormat(salaryData?.add_etc) : 0}원</div>
                                    </div>
                                ) : ''}
                                <div className="hr darkGrayHr"/>
                                <div className="receipt-item title">
                                    <div className="label">공제 총액</div>
                                    <div className="contents">{salaryData?.total_deduct_pay ? comFormat(salaryData?.total_deduct_pay) : 0}원</div>
                                </div>
                                <div className="hr grayHr"/>
                                {salaryData?.pension ? (
                                    <div className="receipt-item">
                                        <div className="label">국민연금</div>
                                        <div className="contents">{salaryData?.pension ? comFormat(salaryData?.pension) : 0}원</div>
                                    </div>
                                ) : ''}
                                {salaryData?.health_insurance ? (
                                    <div className="receipt-item">
                                        <div className="label">건강보험</div>
                                        <div className="contents">{salaryData?.health_insurance ? comFormat(salaryData?.health_insurance) : 0}원</div>
                                    </div>
                                ) : ''}
                                {salaryData?.employment_insurance ? (
                                    <div className="receipt-item">
                                        <div className="label">고용보험</div>
                                        <div className="contents">{salaryData?.employment_insurance ? comFormat(salaryData?.employment_insurance) : 0}원</div>
                                    </div>
                                ) : ''}
                                {salaryData?.income_tax ? (
                                    <div className="receipt-item">
                                        <div className="label">소득세</div>
                                        <div className="contents">{salaryData?.income_tax ? comFormat(salaryData?.income_tax) : 0}원</div>
                                    </div>
                                ) : ''}
                                {salaryData?.local_income_tax ? (
                                    <div className="receipt-item">
                                        <div className="label">지방소득세</div>
                                        <div className="contents">{salaryData?.local_income_tax ? comFormat(salaryData?.local_income_tax) : 0}원</div>
                                    </div>
                                ) : ''}
                                {salaryData?.late_deduct ? (
                                    <div className="receipt-item">
                                        <div className="label">지각차감</div>
                                        <div className="contents">{salaryData?.late_deduct ? comFormat(salaryData?.late_deduct) : 0}원</div>
                                    </div>
                                ) : ''}
                                {salaryData?.absence_deduct ? (
                                    <div className="receipt-item">
                                        <div className="label">결근</div>
                                        <div className="contents">{salaryData?.absence_deduct ? comFormat(salaryData?.absence_deduct) : 0}원</div>
                                    </div>
                                ) : ''}
                                {salaryData?.holiday_deduct ? (
                                    <div className="receipt-item">
                                        <div className="label">무급휴가</div>
                                        <div className="contents">{salaryData?.holiday_deduct ? comFormat(salaryData?.holiday_deduct) : 0}원</div>
                                    </div>
                                ) : ''}
                                {salaryData?.day_off_deduct ? (
                                    <div className="receipt-item">
                                        <div className="label">연차차감</div>
                                        <div className="contents">{salaryData?.day_off_deduct ? comFormat(salaryData?.day_off_deduct) : 0}원</div>
                                    </div>
                                ) : ''}
                                {salaryData?.minus_etc ? (
                                    <div className="receipt-item">
                                        <div className="label">직접입력</div>
                                        <div className="contents">{salaryData?.minus_etc ? comFormat(salaryData?.minus_etc) : 0}원</div>
                                    </div>
                                ) : ''}
                                {salaryData?.deduction ? (
                                    <div className="receipt-item">
                                        <div className="label">공제 항목</div>
                                        <div className="contents">{salaryData?.deduction ? comFormat(salaryData?.deduction) : 0}원</div>
                                    </div>
                                ) : ''}
                                {salaryData?.calculate ? (
                                    <div className="receipt-item">
                                        <div className="label">정산금</div>
                                        <div className="contents">{salaryData?.calculate}원</div>
                                    </div>
                                ) : ''}
                                <div className="hr darkGrayHr"/>
                                <div className="receipt-item title mt-10">
                                    <div className="label">최종 수령액</div>
                                    <div className="contents blueTextColor boldText">{salaryData?.total_issue_pay - salaryData?.total_deduct_pay ? comFormat(salaryData?.total_issue_pay - salaryData?.total_deduct_pay) : 0}원</div>
                                </div>
                                <div className="hr darkGrayHr"/>
                            </>
                        )}
                        <div className="receipt-item">
                            <div className="label">정산 기준</div>
                            <div className="contents">{salaryData?.begin_date} ~ {salaryData?.end_date}</div>
                        </div>
                        <div className="receipt-item">
                            <div className="label">급여 지급일</div>
                            <div className="contents">{salaryData?.issue_date}</div>
                        </div>
                    </div>
                </PageBody>
            </ContentsSection>
        </>
    );
};

export default SalaryDetail;