import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BottomErrMsg } from "component/basic/popup";
import { ContentsSection, InputSection } from "component/app/items";
import {
    InputItemBox,
    CustomSelect,
    TextAreaItem,
    DatePicerRange,
    FileItemBox
} from "component/basic/formItems";
import { BtnBox, BtnItem } from "component/basic/btns";
import { Header } from "component/elements/header";

const BoardRegister = (props) => {
    const navigate = useNavigate();

    const [title, setTitle] =  useState('');
    const [category, setCategory] =  useState('');
    const [contents, setContents] =  useState('');
    const [file, setFile] =  useState();
    const [dateType, setDateType] =  useState(1);
    const [beginDate, setBeginDate] =  useState('');
    const [endDate, setEndDate] =  useState('');
    const [publicType, setPublicType] =  useState('all');
    const [departmentId, setDepartmentId] =  useState('');
    const [departmentName, setDepartmentName] =  useState('');
    const [publicTargets, setPublicTargets] =  useState([]);

    const [btnChk,setBtnChk] =  useState(true);
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});
    const [inputErr,setInputErr] =  useState(false);

    const departmentApi = useGet({url:`/department?company_id=${localStorage.getItem("companyId")}`, loginType:"login"});
    const departmentData = departmentApi?.data?.departments;

    const positionApi = useGet({url:`/position?company_id=${localStorage.getItem("companyId")}&department_id=${departmentId}`, loginType:"login"});
    const positionData = positionApi?.data?.positions;

    function handleSubmit(){
        let formData = new FormData();
        formData.append("company_id", localStorage.getItem("companyId"));
        formData.append("title", title);
        formData.append("category", category);
        formData.append("contents", contents);
        formData.append("begin_date", beginDate);
        formData.append("end_date", endDate);
        formData.append("public_type", publicType.toString());
        if (file && file?.size > (5 * 1024 * 1024)) {
            alert('5MB 이하의 파일만 업로드 가능합니다')
            return false
        }
        formData.append("files[0]", file);
        for (let i = 0; i < publicTargets?.length; i++) {
            formData.append(`public_targets[${i}]`, publicTargets[i]?.id);
        }

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: "/board/register",
            loginType: "login",
            success: (data) => {
                navigate(`/board`);
            },
            err: (data) => {
                if(data.data || data.alert){
                    setBottomMsgData({
                        text : data.alert||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    }

    useEffect(() => {
        if (title !== "" && category !== "" && contents !== "" && dateType !== "" && publicType !== "" && (dateType === 1 || (dateType === 2 && beginDate && endDate)) && (publicType === 'all' || (publicType === 'section' && publicTargets?.length > 0))) {
            setBtnChk(false)
        } else {
            setBtnChk(true)
        }
    }, [title, category, contents, dateType, beginDate, endDate, publicType, publicTargets]);

    return (
        <>
            <Header
                pageBack={true}
                func={() => {
                    navigate(-1)
                }}
                headTitle="게시글 작성"
                centerTitle={true}
            />
            <ContentsSection addClass="headerContents btnContents">
                <InputSection addClass="registerSection">
                    <InputItemBox
                        inputType="text"
                        inputTitle="제목"
                        addClass={inputErr ? "err" : ""}
                        placeholder="제목을 입력해주세요"
                        value={title}
                        max={null}
                        regexp={null}
                        func={(value)=>{setTitle(value)}}
                    />
                    <CustomSelect
                        addClass=""
                        inputTitle="카테고리"
                        placeholder="카테고리명"
                        value={category}
                        func={(value) => {
                            setCategory(value);
                        }}
                        disabled={false}
                        valKey="name"
                        nameKey="name"
                        options={[
                            {id: 1, name: "공지"},
                            {id: 2, name: "법정의무교육"},
                            {id: 3, name: "일반"}
                        ]}
                    />
                    <TextAreaItem
                        inputTitle="상세 설명"
                        inputName=""
                        placeholder="내용을 입력해주세요"
                        max={500}
                        maxChk={false}
                        value={contents}
                        func={(value) => setContents(value)}
                    />
                    <FileItemBox
                        addClass=""
                        inputTitle="파일 첨부"
                        placeholder="파일을 등록해주세요"
                        func={(file) => {
                            setFile(file);
                        }}
                    />
                    <CustomSelect
                        addClass=""
                        inputTitle="게시기간"
                        placeholder="기간 설정"
                        value={dateType}
                        func={(name,val,subVal) => {
                            setDateType(val);
                            if (val === 1) {
                                setBeginDate('')
                                setEndDate('')
                            }
                        }}
                        disabled={false}
                        valKey="id"
                        nameKey="name"
                        options={[
                            {id: 1, name: "기한 없음"},
                            {id: 2, name: "기간 설정"},
                        ]}
                    />
                    {dateType === 2 && (
                        <DatePicerRange
                            inputType="text"
                            boxAddClass={'inputGroup'}
                            addClass={(inputErr ? "err" : "")}
                            placeholder="날짜 선택"
                            monthFunc={(date) => {}}
                            value={beginDate + ' ~ ' + endDate}
                            max={null}
                            regexp={null}
                            func={(beginDate, endDate)=> { setBeginDate(formatDate(beginDate)); setEndDate(formatDate(endDate)); }}
                        />
                    )}
                    {/*게시기간*/}
                    <CustomSelect
                        addClass=""
                        inputTitle="공개대상"
                        placeholder=""
                        value={publicType}
                        func={(name,val) => {
                            setPublicType(val);
                            if (val === 'all') {
                                setPublicTargets([])
                            }
                        }}
                        disabled={false}
                        valKey="id"
                        nameKey="name"
                        options={[
                            {id: 'all', name: "전체 공개"},
                            {id: 'section', name: "일부 공개"},
                        ]}
                    />
                    {publicType === 'section' && (
                        <>
                            <CustomSelect
                                addClass=""
                                boxAddClass={'inputGroup'}
                                placeholder="부서 선택"
                                value={departmentId}
                                func={(name,val,subVal) => {
                                    setDepartmentId(val);
                                    setDepartmentName(name);
                                }}
                                disabled={false}
                                valKey="id"
                                nameKey="name"
                                options={departmentData}
                            />
                            <CustomSelect
                                addClass=""
                                boxAddClass={'inputGroup'}
                                placeholder="직급 선택"
                                func={(name,val,subVal) => {
                                    setPublicTargets((prevPublicTargets) => {
                                        if (prevPublicTargets.some(item => item.id === val)) {
                                            return prevPublicTargets
                                        }

                                        return [...prevPublicTargets, {id: val, department: departmentName, position: name}]
                                    })
                                }}
                                disabled={false}
                                valKey="id"
                                nameKey="name"
                                options={positionData?.length > 0 ? positionData : []}
                            />
                            <div className="positionTagBox">
                                {publicTargets?.map((publicTarget, publicTargetKey) => (
                                    <div className="positionTag">{publicTarget?.department} / {publicTarget?.position} <button onClick={() => {
                                        let newPublicTargets = [...publicTargets];
                                        newPublicTargets.splice(publicTargetKey, 1);
                                        setPublicTargets(newPublicTargets);
                                    }}><img src={`/assets/images/basic/close.svg`}/></button></div>
                                ))}
                            </div>
                        </>
                    )}
                </InputSection>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        contents={"등록하기"}
                        disabled={btnChk}
                        func={() => { handleSubmit() }}
                    />
                </BtnBox>
            </ContentsSection>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
        </>
    );
};

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
};

export default BoardRegister;