import useGet from "api/useGet";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {ContentsSection, PageBody, PageSubHeader} from "component/app/items";
import { BtnItem } from "component/basic/btns";
import { Header } from "component/elements/header";
import moment from "moment";
import 'moment/locale/ko';
import { DocumentCard } from "component/document/items";

const Document = (props) => {
    const navigate = useNavigate();

    const [tab, setTab] =  useState("ing");

    const documentApi = useGet({url:`/document?company_id=${localStorage.getItem("companyId")}&type=${tab}`, loginType:"login"});
    const documents = documentApi?.data?.documents;

    function handleResize(){
        var totalSize = 0;
        let headerSize = document.querySelectorAll(".header")[0].clientHeight,
            subTitleSize = document.querySelectorAll(".pageSubHeaderBox")[0].clientHeight,
            menuSize = document.querySelectorAll(".menu")[0].clientHeight,
            pageBtnSize = document.querySelectorAll(".pageBtn")[0].clientHeight,
            viewSize = document.documentElement.clientHeight;

        totalSize = viewSize - (headerSize + subTitleSize + menuSize + pageBtnSize + 2)

        document.querySelectorAll(".pageBody")[0].style.height = `${totalSize}px`;
        document.querySelectorAll(".cardBox")[0].style.height = `${totalSize}px`;
    }

    useEffect(() => {
        handleResize()
    }, [documents]);

    return (
        <>
            <Header
                addClass="pageMainBg"
                menuBtn={true}
                headTitle={documentApi?.data?.company_name}
                centerTitle={true}
            />
            <ContentsSection addClass="headerContents pageMainBg">
                <PageSubHeader
                    addCardClass="subHeaderCard"
                    icon={<img className="icon" src="/assets/images/icon/document.svg"/>}
                    contents="문서신청"
                    menu={<div className="menu">
                        <div className={`menuButton ${tab === 'ing' ? 'active ' : ''}`} onClick={() => setTab('ing')}>진행중인 문서 {documentApi?.data?.ing_count}</div>
                        <div className={`menuButton ${tab === 'complete' ? 'active ' : ''}`} onClick={() => setTab('complete')}>완료된 문서 {documentApi?.data?.complete_count}</div>
                        <div className={`menuButton ${tab === 'cancel' ? 'active ' : ''}`} onClick={() => setTab('cancel')}>취소된 문서</div>
                    </div>}
                />
                <PageBody>
                    <div className="cardBox">
                        {documents?.map((document,i) => (
                            <DocumentCard
                                key={i}
                                func={()=>{
                                    if (document?.status === 1 || document?.status === 2) {
                                        navigate(`/document/sign/${document?.id}`)
                                    } else {
                                        navigate(`/document/detail/${document?.id}`)
                                    }
                                }}
                                type={document?.type}
                                title={document?.title}
                                created_at={document?.created_at}
                                status={document?.status}
                            />
                        ))}
                    </div>
                </PageBody>
                <BtnItem
                    addClass=""
                    contents={"문서발급 신청"}
                    disabled={documentApi?.data?.is_resignation}
                    func={() => {navigate("/document/request")}}
                />
            </ContentsSection>
        </>
    );
};

export default Document;