import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BottomErrMsg } from "component/basic/popup";
import { ContentsSection, InputSection } from "component/app/items";
import {
    InputItemBox,
    CustomSelect,
    TextAreaItem,
    DatePicerRange,
    FileItemBox, BottomPopup
} from "component/basic/formItems";
import { BtnBox, BtnItem } from "component/basic/btns";
import { Header } from "component/elements/header";
import moment from "moment";

const Request = (props) => {
    const navigate = useNavigate();

    const [minDate, setMinDate] =  useState('');
    const [date, setDate] =  useState('');
    const [additionalPayTypeId, setAdditionalPayTypeId] =  useState('');
    const [memo, setMemo] =  useState('');
    const [completePopup, setCompletePopup] =  useState(false);

    const [btnChk,setBtnChk] =  useState(true);
    const [cancelPopup, setCancelPopup] =  useState(false);
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});
    const [inputErr,setInputErr] =  useState(false);

    const additionalPayTypeApi = useGet({url:`/additional/pay/type?company_id=${localStorage.getItem("companyId")}`, loginType:"login"});
    const additionalPayTypeData = additionalPayTypeApi?.data?.additional_pay_types;

    function handleSubmit(){
        let formData = new FormData();
        formData.append("company_id", localStorage.getItem("companyId"));
        formData.append("date", date);
        formData.append("additional_pay_type_id", additionalPayTypeId);
        formData.append("memo", memo);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: "/additional/pay/request",
            loginType: "login",
            success: (data) => {
                setCompletePopup(true)
            },
            err: (data) => {
                if(data.data || data.alert){
                    setBottomMsgData({
                        text : data.alert||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    }

    useEffect(() => {
        if (date !== "" && additionalPayTypeId !== "" && memo !== "") {
            setBtnChk(false)
        } else {
            setBtnChk(true)
        }
    }, [date, additionalPayTypeId, memo]);

    useEffect(() => {
        if (additionalPayTypeData) {
            setMinDate(additionalPayTypeApi?.data?.salary_history_info?.max_month ? moment(additionalPayTypeApi?.data?.salary_history_info?.max_month).add(1, 'days').format('YYYY-MM-DD') : null)
        }
    }, [additionalPayTypeData])

    return (
        <>
            <Header
                pageBack={true}
                func={() => {
                    setCancelPopup(true)
                }}
                headTitle="추가수당 신청"
                centerTitle={true}
            />
            <ContentsSection addClass="headerContents btnContents">
                <InputSection addClass="registerSection mt-20">
                    <InputItemBox
                        inputType="date"
                        inputTitle="수당 발생 근무일"
                        addClass={"inputDate"}
                        placeholder="날짜 선택"
                        minDate={minDate}
                        value={date}
                        max={null}
                        regexp={null}
                        func={(value)=>{setDate(value)}}
                    />
                    <CustomSelect
                        addClass=""
                        inputTitle="추가수당 종류"
                        placeholder="추가수당 종류"
                        value={additionalPayTypeId}
                        func={(name,val,subVal) => {
                            setAdditionalPayTypeId(val);
                        }}
                        disabled={false}
                        valKey="id"
                        nameKey="type"
                        options={additionalPayTypeData ? additionalPayTypeData : []}
                    />
                    <TextAreaItem
                        inputTitle="메모"
                        inputName=""
                        placeholder="내용을 입력해주세요"
                        max={500}
                        maxChk={false}
                        value={memo}
                        func={(value) => setMemo(value)}
                    />
                </InputSection>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        contents={"신청하기"}
                        disabled={btnChk}
                        func={() => { handleSubmit() }}
                    />
                </BtnBox>
            </ContentsSection>
            <BottomPopup
                open={completePopup}
                title="추가수당 신청 완료"
                subTitle={<div>추가수당 신청이 완료되었습니다. <br/>신청 결과는 관리자 승인 후 확인 가능합니다.</div>}
                btn1Text="확인"
                btn1Func={() => { navigate(`/additional`); }}
            />
            <BottomPopup
                open={cancelPopup}
                title="신청을 취소하시겠습니까?"
                subTitle="취소한 신청서는 수정이 불가하며 복구되지 않습니다."
                btn1Text="취소"
                btn2Text="신청 취소"
                btn1Func={() => { setCancelPopup(false) }}
                btn2Func={() => { navigate(-1) }}
            />
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
        </>
    );
};

export default Request;