import Private from "pages/private/Private";
import PublicOnly from "pages/private/publicOnly";
import React, { useEffect, useState } from "react";
import * as fatchSet from "../api/api";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ScrollToTop from "component/basic/scrollTopSet";
import { BottomErrMsg, LoadingBox, Popup, RightPopup } from "component/basic/popup";
import useGet from "api/useGet";
import { ErrorBoundary } from "react-error-boundary";
import { routes } from "./routes";
import { EditerItem } from "component/app/editerItem";
import { PageSizing } from "component/app/items";
import { BtnBox, BtnItem } from "component/basic/btns";
import {getParam} from "../js/function";

const Router = () => {
    const [token,setToken] = useState(localStorage.getItem("token") ? true : false);
    const [terms,setTerms] =  useState("");
    
    const [rightOpenType,setRightOpenType] =  useState(false);
    const [popupData,setPopupData] =  useState(null);
    const [loadingBoxOpen,setLoadingBoxOpen] =  useState(false);
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    const userInfoApi = useGet({url:`/user/info?company_id=${localStorage.getItem("companyId")}`, loginType:"login"});

    window.logIn = function(){
        setToken(true);
    }
    window.logOut = function(){
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");

        localStorage.removeItem("companyId")

        setToken(false);
    }

    window.rightOpen = () => {
        setRightOpenType(true);
    }

    window.rightClose = () => {
        setRightOpenType(false);
    }

    window.termsOpen = (id,title,contents,btnType) => {
        let termsItem = {
            id:id,
            title:title,
            contents:contents,
            btnType:btnType
        }
        setTerms(termsItem);
        setRightOpenType(true);
    }

    window.errPopupOpen = (data) => {
        if(data.data || data.alert){
            setPopupData({
                addClass:null,
                title:data.alert||"",
                text:data.data||"",
                closeType:true,
                closeFunc:(e) => {},
                btnFunc0:(e)=>{},
                btn0:"확인",
                btn0Type:"",
                btn1:"",
                btn1Type:"",
                btnFunc1:()=>{}
            });
        }
    }

    window.errMsg = (msg) => {
        if(msg){
            setBottomMsgData({
                text : msg,
                chk : bottomMsgData.chk + 1
            });
        }
    }

    window.loadingBox = (type) => {
        setLoadingBoxOpen(type);
    }

    window.sns_login = async (type, token) => {
        const formData = new FormData();
        formData.append("code", token);
        formData.append("is_token", 1);

        fetch(`https://api.waveone.fifteenh.io/api/${type}/login`, {
            method: "POST", body: formData
        }).then(function (response) {
            return response.json();
        }).then((data) => {
            if(data.success === true){
                localStorage.setItem("token",data.data.access_token);
                localStorage.setItem("refresh_token",data.data.refresh_token);

                window.logIn();

                if(data.data.is_check_password === 1){
                    if(data.data.company_count === 1){
                        localStorage.setItem("companyId",data.data.company_id);
                        window.location.href = "/workHistory";
                    }else{
                        window.location.href = `/member/company`;
                    }
                }else{
                    window.location.href = `/member/pwSetting`;
                }
            } else {
                window.alert(data.alert);
            }
        }).catch((e) => {
            window.alert('일시적인 오류가 발생했습니다. 잠시 후 다시 시도해주세요');
        });
    };

    window.sns_connect = async (type, token) => {
        let formData = new FormData();
        formData.append("company_id", localStorage.getItem("companyId"));
        if(type === "kakao" || type === "apple"){
            formData.append("code", token);
            formData.append("is_token", 1);
        }

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: `/${type}/connect`,
            loginType: "login",
            success: (data) => {
                window.alert('연동이 완료되었습니다');
                window.location.reload()
            },
            err: (data) => {
                if(data.data || data.alert){
                    setBottomMsgData({
                        text : data.alert||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    };

    useEffect(()=>{
        
    },[]);

    //백화 시 노출
    const ErrorFallback = (err) => {
        console.log(err)
        return (
            <div className="pageSizing">
                <div className="pageErrBox">
                    <h1 className="pageErrTitle">서비스 이용에 불편을 드려 죄송합니다.</h1>
                    <p className="pageErrText">서비스 오류가 발생했습니다.<br/>잠시 후 다시 시도해주세요</p>
                </div>
                <div className="pageBtn_box">
                    <button type="button" className="pageBtn" onClick={() => window.location.href = "/"}>처음으로</button>
                </div>
            </div>
        );
    }

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <BrowserRouter>
                <ScrollToTop/>
                <PageSizing>
                <Routes>
                    {routes.map(
                        ({ exact, path, component, type }) => (
                            <Route
                                key={`public-route-${path}`}
                                exact={exact}
                                path={path}
                                element={type == "login" ? 
                                    token ? component : <Private/>
                                : type == "notLogin" ?  
                                    token ? <PublicOnly/> : component
                                : component}
                            />
                        )
                    )}

                    {/*404*/}
                    <Route
                        key={`public-route-/*`}
                        exact={true}
                        path="/*"
                        element={<PublicOnly/>}
                    />
                </Routes>
                </PageSizing>            
                <RightPopup
                    userInfo={userInfoApi?.data}
                    topTitle={terms?.title}
                    addClass={rightOpenType ? `active ${terms?.btnType ? "bottomBtn" : ""}` : terms?.btnType ? "bottomBtn" : ""}
                    closePopup={() => {setTerms({});setRightOpenType(false)}}
                    openType={rightOpenType}
                    // bType={true}
                >
                    <EditerItem addClass="termsText" contents={terms?.contents}/>
                    {terms?.btnType ? 
                        <BtnBox
                            boxType="fixed"
                            addClass=""
                        >
                            <BtnItem
                                addClass=""
                                contents={terms?.btnText}
                                disabled={false}
                                func={() => {terms?.btnFunc(terms?.id,terms?.title);setTerms({});setRightOpenType(false)}}
                            />
                        </BtnBox>
                    :""}
                </RightPopup>
                
                <Popup
                    data={popupData}
                />
                <BottomErrMsg
                    text={bottomMsgData.text ? bottomMsgData.text : ""}
                    chk={bottomMsgData.chk}
                />
                <LoadingBox openType={loadingBoxOpen}/>
            </BrowserRouter>
        </ErrorBoundary>
    );
};

export default Router;