import useGet from "api/useGet";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {ContentsSection, PageBody, PageSubHeader} from "component/app/items";
import { Header } from "component/elements/header";
import moment from "moment";
import {comFormat} from "../../js/function";

const Salary = (props) => {
    const navigate = useNavigate();

    const [year, setYear] =  useState(moment().format("YYYY"));

    const salaryApi = useGet({url:`/salary?company_id=${localStorage.getItem("companyId")}&year=${year}`, loginType:"login"});
    const salaries = salaryApi?.data?.salaries;

    function handleResize(){
        var totalSize = 0;
        let headerSize = document.querySelectorAll(".header")[0].clientHeight,
            subTitleSize = document.querySelectorAll(".pageSubHeaderBox")[0].clientHeight,
            selectYearBoxSize = document.querySelectorAll(".selectYearBox")[0].clientHeight,
            viewSize = document.documentElement.clientHeight;

        totalSize = viewSize - (headerSize + subTitleSize + 17)

        document.querySelectorAll(".pageBody")[0].style.height = `${totalSize}px`;
        document.querySelectorAll(".cardBox")[0].style.height = `${totalSize - selectYearBoxSize}px`;
    }

    useEffect(() => {
        handleResize()
    }, [salaries]);

    return (
        <>
            <Header
                addClass="pageMainBg"
                menuBtn={true}
                headTitle={salaryApi?.data?.company_name}
                centerTitle={true}
            />
            <ContentsSection addClass="headerContents pageMainBg">
                <PageSubHeader
                    addCardClass="subHeaderCard"
                    icon={<img className="icon" src="/assets/images/icon/salary.svg"/>}
                    contents="급여확인"
                />
                <PageBody
                    useYear={true}
                    year={year}
                    setYear={setYear}>
                    <div className="cardBox">
                        {salaries?.map((salary) => (
                            <div className="card" onClick={() => { salary?.id && navigate(`/salary/detail/${salary?.id}`) }}>
                                <div className="cardTitleBox">
                                    <div className="title">{salary?.date}월</div>
                                </div>
                                <div className="cardContentsBox">
                                    <div className="item">
                                        <div className="label">총 지급액</div>
                                        <div className="contents">{comFormat(salary?.total_issue_pay)}원</div>
                                    </div>
                                    {salaryApi?.data?.company_plan === 'premium' && (
                                        <>
                                            <div className="item">
                                                <div className="label">총 공제액</div>
                                                <div className="contents">{comFormat(salary?.total_deduct_pay)}원</div>
                                            </div>
                                            <div className="item">
                                                <div className="label">차인지급액</div>
                                                <div className="contents blueTextColor boldText">{comFormat(salary?.total_issue_pay - salary?.total_deduct_pay)}원</div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </PageBody>
            </ContentsSection>
        </>
    );
};

export default Salary;