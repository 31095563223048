import React from 'react';
import ReactDOM from 'react-dom/client';
import Router from './router/Router';

import "./css/basic.css";
import "./css/popup.css";
import "./css/btn.css";
import "./css/style.css";

import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://c8e69880bfaa34d302b3855a8acfe27b@o4507456368803840.ingest.de.sentry.io/4507749691031632",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router />
  </React.StrictMode>
);