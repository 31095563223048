import * as fatchSet from "../../api/api";
import React, { useState } from "react";
import { useEffect } from "react";
import {useNavigate, useParams} from "react-router-dom";
import { BottomErrMsg } from "component/basic/popup";
import { ContentsSection, InputSection } from "component/app/items";
import { CustomSelect, TextAreaItem, BottomPopup, DatePicerRange } from "component/basic/formItems";
import { BtnBox, BtnItem } from "component/basic/btns";
import { Header } from "component/elements/header";
import moment from "moment";
import useGet from "../../api/useGet";

const Detail = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const id = param.id

    const [cancelPopup, setCancelPopup] =  useState(false);
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    const dayOffTApi = useGet({url:`/day/off/detail?company_id=${localStorage.getItem("companyId")}&day_off_id=${id}`, loginType:"login"});
    const dayOff = dayOffTApi?.data?.day_off;

    function handleCancel() {
        let formData = new FormData();
        formData.append("company_id", localStorage.getItem("companyId"));
        formData.append("day_off_id", dayOff?.id);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            loginType:"login",
            url: `/day/off/detail/cancel`,
            success: (data) => {
                setCancelPopup(false)
                navigate('/dayoff')
            },
            err: (data) => {
                if(data.data || data.alert){
                    setBottomMsgData({
                        text : data.alert||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    }

    return (
        <>
            <Header
                pageBack={true}
                func={() => {
                    navigate(-1)
                }}
                headTitle="휴가 신청 정보"
                centerTitle={true}
            />
            <ContentsSection addClass="headerContents btnContents">
                <InputSection addClass="registerSection mt-20">
                    <DatePicerRange
                        inputType="text"
                        inputTitle="휴가 신청일"
                        boxAddClass="inputGroup"
                        placeholder="날짜 선택"
                        value={"value"}
                        beginDate={dayOff?.begin_date ? dayOff?.begin_date : ''}
                        endDate={dayOff?.end_date ? dayOff?.end_date : ''}
                        max={null}
                        regexp={null}
                        disabled={true}
                    />
                    {dayOff?.day_off_details?.map((dayOffDetail, dayOffDetailKey) => (
                        <CustomSelect
                            addClass=""
                            inputTitle={`연차 형태 | ${moment(dayOffDetail?.date)?.format("YYYY-MM-DD")} (${moment(dayOffDetail?.date).format('dddd').replace('요일', '')})`}
                            placeholder="선택"
                            disabled={true}
                            valKey="id"
                            nameKey="name"
                            value={dayOffDetail?.type}
                            options={[
                                {id: 'day', name: (dayOffDetail?.is_unpaid === 1 ? '무급 ' : '') + "연차 1일 (8시간)"},
                                {id: 'morning', name: (dayOffDetail?.is_unpaid === 1 ? '무급 ' : '') + "오전반차 | 09:00 ~ 13:00 (4시간)"},
                                {id: 'afternoon', name: (dayOffDetail?.is_unpaid === 1 ? '무급 ' : '') + "오후반차 | 14:00 ~ 18:00 (4시간)"},
                            ]}
                        />
                    ))}
                    <TextAreaItem
                        inputTitle="메모"
                        inputName=""
                        placeholder="내용을 입력해주세요"
                        max={500}
                        maxChk={false}
                        disabled={true}
                        value={dayOff?.memo}
                    />
                    {(dayOff?.status === 1 || dayOff?.status === 2) && (
                        <>
                            <CustomSelect
                                inputTitle="승인 여부"
                                boxAddClass={'inputGroup'}
                                value={dayOff?.status}
                                disabled={true}
                                valKey="id"
                                nameKey="name"
                                options={[
                                    {id: 1, name: "승인"},
                                    {id: 2, name: "반려"},
                                ]}
                            />
                            <TextAreaItem
                                inputTitle="관리자 메시지"
                                inputName=""
                                placeholder="내용을 입력해주세요"
                                max={500}
                                maxChk={false}
                                disabled={true}
                                value={dayOff?.admin_message}
                            />
                        </>
                    )}
                </InputSection>
                {(dayOff?.status === 0 || dayOff?.status === 3) && (
                    <BtnBox
                        boxType="fixed"
                    >
                        <BtnItem
                            addClass=""
                            contents={dayOff?.status !== 0 ? '신청 취소됨' : '신청 취소하기'}
                            btnColor="btnDarkGray"
                            disabled={dayOff?.status !== 0}
                            func={() => { setCancelPopup(true) }}
                        />
                    </BtnBox>
                )}
            </ContentsSection>
            <BottomPopup
                open={cancelPopup}
                title="신청을 취소하시겠습니까?"
                subTitle="취소한 신청서는 수정이 불가하며 복구되지 않습니다."
                btn1Text="취소"
                btn2Text="신청 취소"
                btn1Func={() => { setCancelPopup(false) }}
                btn2Func={() => { handleCancel() }}
            />
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
        </>
    );
};

export default Detail;

const generateDateRange = (start, end) => {
    let startDate = moment(start);
    const endDate = moment(end);
    const dates = [];

    while (startDate <= endDate) {
        dates.push(startDate.format('YYYY-MM-DD'));
        startDate = startDate.add(1, 'days');
    }

    return dates;
};