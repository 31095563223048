import useGet from "api/useGet";
import React, {useEffect, useRef, useState} from "react";
import {ContentsSection, PageSubHeader, PageBody} from "component/app/items";
import { Header } from "component/elements/header";
import {BtnItem} from "../../component/basic/btns";
import {useNavigate} from "react-router-dom";
import {getParam} from "../../js/function";
import * as fatchSet from "../../api/api";
import {BottomErrMsg} from "../../component/basic/popup";

const Mypage = (props) => {
    const varUA = navigator.userAgent;
    const navigate = useNavigate();
    const type = getParam("type");

    const scrollRef = useRef(null);
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});
    const [update, setUpdate] =  useState(0);

    const mypageApi = useGet({url:`/mypage?company_id=${localStorage.getItem("companyId")}&update=${update}`, loginType:"login"});
    const mypageData = mypageApi?.data?.user;

    function handleResize(){
        var totalSize = 0;
        let subTitleSize = document.querySelectorAll(".pageSubHeaderBox")[0].clientHeight,
            headerSize = document.querySelectorAll(".header")[0].clientHeight,
            pageBtnSize = document.querySelectorAll(".pageBtn")[0].clientHeight,
            profileBoxSize = document.querySelectorAll(".profileBoxTitle")[0].clientHeight,
            viewSize = document.documentElement.clientHeight;

        totalSize = viewSize - (subTitleSize + headerSize + pageBtnSize + 2)

        document.querySelectorAll(".pageBody")[0].style.height = `${totalSize}px`;
        document.querySelectorAll(".profileBox")[0].style.height = `${totalSize - profileBoxSize}px`;
    }

    useEffect(() => {
        handleResize()
    }, [mypageData]);

    //카카오
    function kakaoLoginStart() {
        if (varUA.match("waveone/Android") != null) {
            window.waveone.kakaoLoginCheck('update')
        } else if (varUA.match("waveone/iOS") != null) {
            window.webkit.messageHandlers.kakaoConnectHandler.postMessage('_');
        } else {
            window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_KAKAO_CONNECT_REDIRECT_URL}&response_type=code`;
        }
    }

    function appleLoginStart(){
        window.location.href = `https://appleid.apple.com/auth/authorize?client_id=${process.env.REACT_APP_APPLE_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_APPLE_CONNECT_REDIRECT_URL}`;
    }

    useEffect(() => {
        if(type){
            let formData = new FormData();
            formData.append("company_id", localStorage.getItem("companyId"));
            if(type === "kakao" || type === "apple"){
                formData.append("code", getParam("code"));
            }

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                url: `/${type}/connect`,
                loginType: "login",
                success: (data) => {
                    setUpdate(update + 1)
                },
                err: (data) => {
                    if(data.data || data.alert){
                        setBottomMsgData({
                            text : data.alert||"",
                            chk : bottomMsgData.chk + 1
                        });
                    }
                }
            })
        }
    }, []);

    return (
        <>
            <Header
                addClass="pageMainBg"
                menuBtn={true}
                headTitle={mypageApi?.data?.company_name}
                centerTitle={true}
            />
            <ContentsSection addClass="headerContents pageMainBg">
                <PageSubHeader
                    icon={<img className="icon" src="/assets/images/icon/user.svg"/>}
                    contents="내 정보"
                />
                <PageBody useYear={false}>
                    <div className="profileBoxTitle">개인정보</div>
                    <div className="profileBox" ref={scrollRef}>
                        <div className="profileItem">
                            <div className="title">부서/직책</div>
                            <div className="value">{mypageData?.type === 2 ? '대표' : (<>{mypageData?.department}/{mypageData?.position}</>)}</div>
                        </div>
                        <div className="profileItem">
                            <div className="title">성명</div>
                            <div className="value">{mypageData?.name}</div>
                        </div>
                        {mypageData?.type === 2 ? (
                            <>
                                <div className="profileItem">
                                    <div className="title">사업자 등록번호</div>
                                    <div className="value">{mypageData?.company_registration_number?.replace(/^(\d{0,3})(\d{0,2})(\d{0,5}).*/, '$1-$2-$3')?.replace(/-*$/, '')}</div>
                                </div>
                                <div className="profileItem">
                                    <div className="title">대표자 생년월일</div>
                                    <div className="value">{mypageData?.ceo_birth?.replace(/^(\d{6})(\d)$/, '$1-$2*******')}</div>
                                </div>
                                <div className="profileItem">
                                    <div className="title">대표자 전화번호</div>
                                    <div className="value">{mypageData?.phone?.replace(/^(\d{0,3})(\d{0,4})(\d{0,4}).*/, '$1-$2-$3')?.replace(/-*$/, '')}</div>
                                </div>
                                <div className="profileItem">
                                    <div className="title">개업 연월일</div>
                                    <div className="value">{mypageData?.open_date}</div>
                                </div>
                                <div className="profileItem">
                                    <div className="title">대표 이메일</div>
                                    <div className="value">{mypageData?.email}</div>
                                </div>
                                <div className="profileItem">
                                    <div className="title">사업장 주소</div>
                                    <div className="value">{mypageData?.address} {mypageData?.address_detail}</div>
                                </div>
                            </>
                        ) : (<>
                            <div className="profileItem">
                                <div className="title">주민등록번호</div>
                                <div className="value">{mypageData?.registration_number?.replace(/^(\d{6})(\d)$/, '$1-$2*******')}</div>
                            </div>
                            <div className="profileItem">
                                <div className="title">전화번호</div>
                                <div className="value">{mypageData?.phone?.replace(/^(\d{0,3})(\d{0,4})(\d{0,4}).*/, '$1-$2-$3')?.replace(/-*$/, '')}</div>
                            </div>
                            <div className="profileItem">
                                <div className="title">입사일</div>
                                <div className="value">{mypageData?.enter_date}</div>
                            </div>
                            <div className="profileItem">
                                <div className="title">퇴사일</div>
                                <div className="value">{mypageData?.resignation_date ? mypageData?.resignation_date : <div className="categoryTag redTag">근무중</div>}</div>
                            </div>
                            <div className="profileItem">
                                <div className="title">이메일</div>
                                <div className="value">{mypageData?.email}</div>
                            </div>
                            <div className="profileItem">
                                <div className="title">주소</div>
                                <div className="value">{mypageData?.address}</div>
                            </div>
                            <div className="profileItem">
                                <div className="title">상세주소</div>
                                <div className="value">{mypageData?.address_detail}</div>
                            </div>
                            <div className="profileItem">
                                <div className="title">급여 계좌</div>
                                <div className="value">{mypageData?.bank} {mypageData?.bank_account}</div>
                            </div>
                        </>)}
                        <div className="profileItem">
                            <div className="title">카카오톡</div>
                            <div className="value">{mypageData?.kakao_provider_id ? (<div className="gTextColor semiBoldText">연동완료</div>) : (<button className="gTextColor semiBoldText gTextUnderline" onClick={() => kakaoLoginStart() }>연동하기</button>)}</div>
                        </div>
                        <div className="profileItem">
                            <div className="title">애플</div>
                            <div className="value">{mypageData?.apple_provider_id ? (<div className="gTextColor semiBoldText">연동완료</div>) : (<button className="gTextColor semiBoldText gTextUnderline" onClick={() => appleLoginStart() }>연동하기</button>)}</div>
                        </div>
                        <div className="termsBox">
                            <a href="" target="_blank">이용약관</a> ㅣ <a href="https://waveglobals.notion.site/WAVEONE-2023-11-01-f9d5a301be4445fb80f64cd61e22ab79" target="_blank">개인정보처리방침</a>
                        </div>
                    </div>
                </PageBody>
                <BtnItem
                    addClass=""
                    contents={"비밀번호 변경"}
                    disabled={false}
                    func={() => {navigate("/mypage/password/modify")}}
                />
                <BottomErrMsg
                    text={bottomMsgData.text ? bottomMsgData.text : ""}
                    chk={bottomMsgData.chk}
                />
            </ContentsSection>
        </>
    );
};

export default Mypage;