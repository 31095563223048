import moment from "moment";
import 'moment/locale/ko';

function DocumentCard(data){
    return (
        <div className="document-card p-0" onClick={() => data?.func()}>
            <div className={`contents ${data?.status === 0 || data?.status ? "" : "notBtn"}`}>
                <div className="titleBox">
                    {data?.type === 'issue' && (<div className="categoryTag blueTag m-0">발급용</div>)}
                    {data?.type === 'write' && (<div className="categoryTag greenTag m-0">작성용</div>)}
                    <div className="name">{data?.title}</div>
                </div>
                <div className="label">요청일시 : {moment(data?.created_at).format("YYYY.MM.DD")} ({moment(data?.created_at).format('dddd').replace('요일', '')})</div>
            </div>
            {data?.status === 0 || data?.status ? 
                <div className={`button ${data?.status === 1 ? 'blue' : 'gray'}`}>{data?.status === 0 ? '신청중' : (data?.status === 1 ? '서명하기' : (data?.status === 2 ? '완료' : (data?.status === 3 ? '반려' : data?.status === 4 ? '취소' : '')))}</div>
            :""}
        </div>
    );
}

export {DocumentCard};