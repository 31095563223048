import useGet from "api/useGet";
import React, {useEffect, useRef, useState} from "react";
import {ContentsSection, PageSubHeader, PageBody, InputSection} from "component/app/items";
import { Header } from "component/elements/header";
import {BtnItem} from "../../component/basic/btns";
import {useNavigate, useParams} from "react-router-dom";
import {CustomSelect, InputItemBox, TextAreaItem} from "../../component/basic/formItems";
import * as fatchSet from "../../api/api";
import {BottomErrMsg} from "../../component/basic/popup";

const ModfiyDetail = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const id = param.id

    const [beginTime, setBeginTime] =  useState('');
    const [endTime, setEndTime] =  useState('');
    const [memo, setMemo] =  useState('');
    const [btnChk,setBtnChk] =  useState(true);
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    const workHistoryApi = useGet({url:`/work/history/detail?company_id=${localStorage.getItem("companyId")}&work_history_id=${id}`, loginType:"login"});
    const workHistoryData = workHistoryApi?.data;

    function handleResize(){
        var totalSize = 0;
        let headerSize = document.querySelectorAll(".header")[0].clientHeight,
            headerCardSize = document.querySelectorAll(".headerItem")[0].clientHeight,
            viewSize = document.documentElement.clientHeight;

        let pageBtnSize = 0
        if (workHistoryData?.work_history_modify_request?.status === 2) {
            pageBtnSize = document.querySelectorAll(".pageBtn")[0].clientHeight
        }

        totalSize = viewSize - (headerSize + headerCardSize + pageBtnSize + 33)

        document.querySelectorAll(".pageBody")[0].style.height = `${totalSize}px`;
    }

    useEffect(() => {
        setBeginTime(workHistoryData?.work_history_modify_request?.request_begin_time)
        setEndTime(workHistoryData?.work_history_modify_request?.request_end_time)
        setMemo(workHistoryData?.work_history_modify_request?.memo)
        handleResize()
    }, [workHistoryData]);

    useEffect(() => {
        if(beginTime && endTime && memo){
            setBtnChk(false);
        }else{
            setBtnChk(true);
        }
    }, [beginTime, endTime, memo]);

    function handleRequest(){
        let formData = new FormData();
        formData.append("company_id", localStorage.getItem("companyId"));
        formData.append("work_history_id", id);
        formData.append("begin_time", beginTime);
        formData.append("end_time", endTime);
        formData.append("memo", memo);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: "/work/modify",
            loginType: "login",
            success: (data) => {
                setBottomMsgData({
                    text : "수정 신청이 완료되었습니다.",
                    chk : bottomMsgData.chk + 1
                });
            },
            err: (data) => {
                if(data.data || data.alert){
                    setBottomMsgData({
                        text : data.alert||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    }

    return (
        <>
            <Header
                pageBack={true}
                func={() => {navigate(-1)}}
                addClass="pageMainBg"
                headTitle="출퇴근 기록 상세"
                centerTitle={true}
            />
            <ContentsSection addClass="headerContents pageMainBg">
                <div className="headerItem">
                    <div className="title">{workHistoryData?.work_history?.date_text}</div>
                    <div className="cardContentsBox">
                        <div className="subItem">
                            <div className="label">계약 근무시간</div>
                            <div className="contents">{workHistoryData?.work_schedule?.begin_time} - {workHistoryData?.work_schedule?.end_time}</div>
                        </div>
                        <div className="subItem">
                            <div className="label">실제 근무시간</div>
                            <div className="contents">{workHistoryData?.work_history?.real_begin_time} - {workHistoryData?.work_history?.real_end_time}</div>
                        </div>
                    </div>
                </div>
                <PageBody useYear={false} addClass="scroll">
                    <InputSection addClass="registerSection">
                        <div className="companyBtnTextBox mb-20">
                            <InputItemBox
                                addClass={`workHistory`}
                                boxAddClass={'col2'}
                                addInputClass={`${workHistoryData?.work_history?.is_absence === 1 ? 'dangerTextColor' : workHistoryData?.work_history?.is_late === 1 ? 'dangerTextColor' : ''}`}
                                inputType="text"
                                inputTitle="기존 근무시간"
                                value={workHistoryData?.work_history?.is_absence === 1 ? workHistoryData?.work_schedule?.begin_time : workHistoryData?.work_history?.real_begin_time}
                                disabled={true}
                                max={null}
                                regexp={null}
                            />
                            <InputItemBox
                                addClass={`workHistory`}
                                boxAddClass={'col2'}
                                addInputClass={`${workHistoryData?.work_history?.is_absence === 1 ? 'dangerTextColor' : ''}`}
                                inputType="text"
                                inputTitle=""
                                value={workHistoryData?.work_history?.is_absence === 1 ? workHistoryData?.work_schedule?.end_time : workHistoryData?.work_history?.real_end_time}
                                disabled={true}
                                max={null}
                                regexp={null}
                            />
                        </div>
                        <div className="companyBtnTextBox mb-20 w-100">
                            <InputItemBox
                                addClass={`workHistory`}
                                boxAddClass={'col2'}
                                inputType="text"
                                inputTitle="변경 근무시간"
                                placeholder="10:03"
                                maxLength={5}
                                disabled={workHistoryData?.work_history_modify_request?.status === 1}
                                value={beginTime}
                                max={null}
                                regexp={null}
                                func={(value)=>{
                                    if (value.length === 4 && !value.includes(':')) {
                                        const minutes = value.slice(0, 2)?.replace(/[^0-9:]/g, "");
                                        const seconds = value.slice(2, 4)?.replace(/[^0-9:]/g, "");
                                        setBeginTime(`${minutes}:${seconds}`);
                                    } else {
                                        setBeginTime(value?.replace(/[^0-9:]/g, ""));
                                    }
                                }}
                            />
                            <InputItemBox
                                addClass={`workHistory`}
                                boxAddClass={'col2'}
                                inputType="text"
                                inputTitle=""
                                placeholder="16:03"
                                maxLength={5}
                                disabled={workHistoryData?.work_history_modify_request?.status === 1}
                                value={endTime}
                                max={null}
                                regexp={null}
                                func={(value)=>{
                                    if (value.length === 4 && !value.includes(':')) {
                                        const minutes = value.slice(0, 2)?.replace(/[^0-9:]/g, "");
                                        const seconds = value.slice(2, 4)?.replace(/[^0-9:]/g, "");
                                        setEndTime(`${minutes}:${seconds}`);
                                    } else {
                                        setEndTime(value?.replace(/[^0-9:]/g, ""));
                                    }
                                }}
                            />
                        </div>
                        <TextAreaItem
                            inputTitle="메모"
                            inputName=""
                            placeholder="내용을 입력해주세요"
                            max={500}
                            maxChk={false}
                            disabled={workHistoryData?.work_history_modify_request?.status === 1}
                            value={memo}
                            func={(value) => setMemo(value)}
                        />
                        <CustomSelect
                            addClass={`workHistory`}
                            inputTitle="승인 여부"
                            boxAddClass={'inputGroup'}
                            value={workHistoryData?.work_history_modify_request?.status}
                            disabled={true}
                            valKey="id"
                            nameKey="name"
                            options={[
                                {id: 1, name: "승인"},
                                {id: 2, name: "반려"},
                            ]}
                        />
                        <TextAreaItem
                            inputTitle="관리자 메시지"
                            inputName=""
                            placeholder="내용을 입력해주세요"
                            max={500}
                            maxChk={false}
                            disabled={true}
                            value={workHistoryData?.work_history_modify_request?.admin_message}
                        />
                    </InputSection>
                </PageBody>
                {workHistoryData?.work_history_modify_request?.status === 2 && (
                    <BtnItem
                        addClass=""
                        contents={"시간변경 재요청"}
                        disabled={btnChk}
                        func={() => {handleRequest()}}
                    />
                )}
            </ContentsSection>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
        </>
    );
};

export default ModfiyDetail;