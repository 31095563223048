import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import React, { useState } from "react";
import {useNavigate, useParams} from "react-router-dom";
import { BottomErrMsg } from "component/basic/popup";
import { ContentsSection, InputSection } from "component/app/items";
import {InputItemBox, CustomSelect, TextAreaItem,BottomPopup
} from "component/basic/formItems";
import { BtnBox, BtnItem } from "component/basic/btns";
import { Header } from "component/elements/header";

const RequestDetail = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const id = param.id

    const [cancelPopup, setCancelPopup] =  useState(false);
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    const additionalPayApi = useGet({url:`/additional/pay/detail?company_id=${localStorage.getItem("companyId")}&additional_pay_id=${id}`, loginType:"login"});
    const additionalPayData = additionalPayApi?.data?.additional_pay;

    function handleCancel(){
        let formData = new FormData();
        formData.append("company_id", localStorage.getItem("companyId"));
        formData.append("additional_pay_id", id);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: "/additional/pay/request/cancel",
            loginType: "login",
            success: (data) => {
                navigate('/additional')
            },
            err: (data) => {
                if(data.data || data.alert){
                    setBottomMsgData({
                        text : data.alert||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    }

    return (
        <>
            <Header
                pageBack={true}
                func={() => {
                    navigate(-1)
                }}
                headTitle="추가수당 신청 정보"
                centerTitle={true}
            />
            <ContentsSection addClass="headerContents btnContents">
                <InputSection addClass="registerSection mt-20">
                    <InputItemBox
                        inputType="date"
                        inputTitle="수당 발생 근무일"
                        addClass={"inputDate"}
                        placeholder="날짜 선택"
                        value={additionalPayData?.date}
                        max={null}
                        regexp={null}
                        disabled={true}
                    />
                    <CustomSelect
                        addClass=""
                        inputTitle="추가수당 종류"
                        placeholder="추가수당 종류"
                        value={additionalPayData?.type}
                        disabled={true}
                        valKey="id"
                        nameKey="type"
                        options={[{id: additionalPayData?.type, type: additionalPayData?.type},]}
                    />
                    <TextAreaItem
                        inputTitle="메모"
                        inputName=""
                        placeholder="내용을 입력해주세요"
                        max={500}
                        disabled={true}
                        maxChk={false}
                        value={additionalPayData?.memo}
                    />
                    {(additionalPayData?.status === 1 || additionalPayData?.status === 2) && (
                        <>
                            <CustomSelect
                                inputTitle="승인 여부"
                                boxAddClass={'inputGroup'}
                                value={additionalPayData?.status}
                                disabled={true}
                                valKey="id"
                                nameKey="name"
                                options={[
                                    {id: 1, name: "승인"},
                                    {id: 2, name: "반려"},
                                ]}
                            />
                            <TextAreaItem
                                inputTitle="관리자 메시지"
                                inputName=""
                                placeholder="내용을 입력해주세요"
                                max={500}
                                maxChk={false}
                                disabled={true}
                                value={additionalPayData?.admin_message}
                            />
                        </>
                    )}
                </InputSection>
                {(additionalPayData?.status === 3 || additionalPayData?.status === 0) && (
                    <BtnBox
                        boxType="fixed"
                        addClass=""
                    >
                        <BtnItem
                            addClass=""
                            contents={additionalPayData?.status === 3 ? "신청 취소됨" : additionalPayData?.status === 0 ? "신청 취소하기" : ""}
                            btnColor="btnDarkGray"
                            disabled={additionalPayData?.status !== 0}
                            func={() => { setCancelPopup(true)}}
                        />
                    </BtnBox>
                )}
            </ContentsSection>
            <BottomPopup
                open={cancelPopup}
                title="신청을 취소하시겠습니까?"
                subTitle="취소한 신청서는 수정이 불가하며 복구되지 않습니다."
                btn1Text="취소"
                btn2Text="신청 취소"
                btn1Func={() => { setCancelPopup(false) }}
                btn2Func={() => { handleCancel() }}
            />
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
        </>
    );
};

export default RequestDetail;