import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import React, { useState } from "react";
import {useNavigate, useParams} from "react-router-dom";
import { BottomErrMsg } from "component/basic/popup";
import { ContentsSection, InputSection } from "component/app/items";
import {BottomPopup, CustomSelect, TextAreaItem,} from "component/basic/formItems";
import { BtnBox, BtnItem } from "component/basic/btns";
import { Header } from "component/elements/header";

const Detail = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const id = param.id

    const [cancelPopup, setCancelPopup] =  useState(false);

    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    const documentApi = useGet({url:`/document/detail?company_id=${localStorage.getItem("companyId")}&document_id=${id}`, loginType:"login"});
    const documentData = documentApi?.data?.document;

    const documentTypeApi = useGet({url:`/document/type?company_id=${localStorage.getItem("companyId")}`, loginType:"login"});
    const documentTypeData = documentTypeApi?.data?.document_types;

    function handleCancel(){
        let formData = new FormData();
        formData.append("company_id", localStorage.getItem("companyId"));
        formData.append("document_id", id);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: "/document/request/cancel",
            loginType: "login",
            success: (data) => {
                setCancelPopup(false)
                navigate('/document')
            },
            err: (data) => {
                if(data.data || data.alert){
                    setBottomMsgData({
                        text : data.alert||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    }

    return (
        <>
            <Header
                pageBack={true}
                func={() => {
                    navigate(-1)
                }}
                headTitle="문서발급 신청 정보"
                centerTitle={true}
            />
            <ContentsSection addClass="headerContents btnContents">
                <InputSection addClass="registerSection">
                    <CustomSelect
                        inputTitle="문서 형태"
                        placeholder="선택"
                        value={documentData?.document_type_id}
                        disabled={true}
                        valKey="id"
                        nameKey="title"
                        options={documentTypeData ? documentTypeData : []}
                    />
                    <TextAreaItem
                        inputTitle="메모"
                        inputName=""
                        placeholder="내용을 입력해주세요"
                        max={500}
                        maxChk={false}
                        disabled={true}
                        value={documentData?.memo}
                    />
                    {(documentData?.status === 2 || documentData?.status === 3) && (
                        <>
                            <CustomSelect
                                inputTitle="승인 여부"
                                boxAddClass={'inputGroup'}
                                value={documentData?.status}
                                disabled={true}
                                valKey="id"
                                nameKey="name"
                                options={[
                                    {id: 2, name: "승인"},
                                    {id: 3, name: "반려"},
                                ]}
                            />
                            <TextAreaItem
                                inputTitle="관리자 메시지"
                                inputName=""
                                placeholder="내용을 입력해주세요"
                                max={500}
                                maxChk={false}
                                disabled={true}
                                value={documentData?.admin_message}
                            />
                        </>
                    )}
                </InputSection>
                {(documentData?.status === 0 || documentData?.status === 4) && (
                    <BtnBox
                        boxType="fixed"
                        addClass=""
                    >
                        <BtnItem
                            addClass=""
                            contents={documentData?.status === 0 ? '신청 취소하기' : '신청 취소됨'}
                            btnColor="btnDarkGray"
                            disabled={documentData?.status === 4}
                            func={() => { setCancelPopup(true)}}
                        />
                    </BtnBox>
                )}
                <BottomPopup
                    open={cancelPopup}
                    title="신청을 취소하시겠습니까?"
                    subTitle="취소한 신청서는 수정이 불가하며 복구되지 않습니다."
                    btn1Text="취소"
                    btn2Text="신청 취소"
                    btn1Func={() => { setCancelPopup(false) }}
                    btn2Func={() => { handleCancel() }}
                />
            </ContentsSection>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
        </>
    );
};

export default Detail;