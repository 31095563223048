import useGet from "api/useGet";
import React, {useEffect, useState, useRef} from "react";
import {ContentsSection, BoardCard, PageSubHeader, PageBody} from "component/app/items";
import { Header } from "component/elements/header";
import {BtnItem} from "../../component/basic/btns";
import { pageBottomChk } from "js/function";
import {useNavigate} from "react-router-dom";

const BoardIndex = (props) => {
    const navigate = useNavigate();

    const [page, setPage] =  useState(1);
    const [year, setYear] =  useState('2024');
    const [itemList, setItemList] =  useState([]);

    const lastPage =  useRef(false)
    const scrollRef = useRef(null);

    const boardApi = useGet({
        url:`/board?page=${page}&company_id=${localStorage.getItem("companyId")}&year=${year}`,
        loginType:"login"
    });
    const boardData = boardApi?.data?.boards;

    function handleResize(){
        var totalSize = 0;
        var selectYearBoxSize = document.querySelectorAll(".selectYearBox")[0].clientHeight;
        if (boardApi?.data?.is_board_permission === 1) {
            let subTitleSize = document.querySelectorAll(".pageSubHeaderBox")[0].clientHeight,
                headerSize = document.querySelectorAll(".header")[0].clientHeight,
                pageBtnSize = document.querySelectorAll(".pageBtn")[0].clientHeight,
                viewSize = document.documentElement.clientHeight;

            totalSize = viewSize - (subTitleSize + headerSize + pageBtnSize)
        } else {
            let subTitleSize = document.querySelectorAll(".pageSubHeaderBox")[0].clientHeight,
                headerSize = document.querySelectorAll(".header")[0].clientHeight,
                viewSize = document.documentElement.clientHeight;

            totalSize = viewSize - (subTitleSize + headerSize)
        }

        document.querySelectorAll(".pageBody")[0].style.height = `${totalSize}px`;
        document.querySelectorAll(".cardBox")[0].style.height = `${totalSize - selectYearBoxSize}px`;
    }

    useEffect(() => {
        if (boardData?.data && boardData?.data.length > 0) {
            if (page == 1) {
                setItemList([...boardData?.data]);
            } else {
                setItemList(prev => [...prev, ...boardData?.data]);
            }
        } else {
            setItemList([]);
        }

        lastPage.current = boardData?.current_page >= boardData?.last_page;
        handleResize()
    }, [boardData]);

    useEffect(() => {
        if (scrollRef.current) {
            const scrollRefCurrent = scrollRef.current
            scrollRefCurrent.removeEventListener("scroll", handleScroll);
            scrollRefCurrent.addEventListener("scroll", handleScroll);
            return () => {
                scrollRefCurrent.removeEventListener("scroll", handleScroll);
            };
        }
    }, [scrollRef]);

    function handleScroll(){
        if (pageBottomChk(".cardBox") && !lastPage.current){
            setPage(page + 1);
        }
    };

    return (
        <>
            <Header
                addClass="pageMainBg"
                menuBtn={true}
                headTitle={boardApi?.data?.company_name}
                centerTitle={true}
            />
            <ContentsSection addClass="headerContents pageMainBg">
                <PageSubHeader
                    icon={<img className="icon" src="/assets/images/icon/board.svg"/>}
                    contents="게시판"
                />
                <PageBody
                    useYear={true}
                    year={year}
                    setYear={setYear}>
                    <div className="cardBox" ref={scrollRef}>
                        <BoardCard data={itemList} />
                    </div>
                </PageBody>
                {boardApi?.data?.is_board_permission === 1 && (
                    <BtnItem
                        addClass=""
                        contents={"게시글 작성"}
                        disabled={false}
                        func={() => {navigate("/board/register")}}
                    />
                )}
            </ContentsSection>
        </>
    );
};

export default BoardIndex;