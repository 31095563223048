import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BottomErrMsg, Popup } from "component/basic/popup";
import { ContentsSection, InputSection, PageTitle } from "component/app/items";
import { InputItemBox } from "component/basic/formItems";
import { BtnBox, BtnItem } from "component/basic/btns";
import { CompanyBtn, SnsBtn } from "component/member/items";
import { Header } from "component/elements/header";
import { NotItems } from "component/basic/notItems";

const PwModify = (props) => {
    const navigate = useNavigate();

    const companyId = localStorage.getItem("companyId") ? localStorage.getItem("companyId") : ''

    const [originalPw,setOriginalPw] =  useState("");
    const [pw,setPw] =  useState("");
    const [pwCom,setPwCom] =  useState("");

    const [btnChk,setBtnChk] =  useState(true);
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});
    const [inputErr,setInputErr] =  useState(false);

    function pwSetting(){
        let formData = new FormData();
        formData.append("company_id", companyId);
        formData.append("original_password", originalPw);
        formData.append("password", pw);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: "/password/modify",
            loginType: "login",
            success: (data) => {
                setBottomMsgData({
                    text : "비밀번호 변경이 완료되었습니다.",
                    chk : bottomMsgData.chk + 1
                });
                navigate("/mypage");
            },
            err: (data) => {
                if(data.data || data.alert){
                    setInputErr(true);
                    setBottomMsgData({
                        text : data.alert||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    }

    useEffect(() => {
        let pwType = /^(?=.*[a-zA-Z])(?=.*[0-9]).{6,}$/;

        setInputErr(false);

        if(originalPw && pwType.test(pw) && pw !== "" && pwType.test(pwCom) && pwCom !== "" && pw === pwCom){
            setBtnChk(false);
        }else{
            setBtnChk(true);
        }
    }, [originalPw, pw, pwCom]);

    return (
        <>
            <Header
                pageBack={true}
                func={() => {
                    navigate(-1)
                }}
                headTitle="비밀번호 변경"
                centerTitle={true}
            />
            <ContentsSection addClass="headerContents btnContents mt-20">
                <InputSection>
                    <InputItemBox
                        inputType="password"
                        addClass={inputErr ? "err" : ""}
                        inputName="현재 비밀번호"
                        placeholder="현재 비밀번호"
                        pwChType={true}
                        value={originalPw}
                        max={null}
                        regexp={null}
                        func={(value)=>{setOriginalPw(value)}}
                    />
                    <InputItemBox
                        inputType="password"
                        addClass={inputErr ? "err" : ""}
                        inputName="새 비밀번호"
                        placeholder="새 비밀번호"
                        pwChType={true}
                        value={pw}
                        max={null}
                        regexp={null}
                        func={(value)=>{setPw(value)}}
                    />
                    <InputItemBox
                        inputType="password"
                        addClass={inputErr ? "err" : ""}
                        inputName="새 비밀번호 확인"
                        placeholder="새 비밀번호 확인"
                        pwChType={true}
                        value={pwCom}
                        max={null}
                        regexp={null}
                        func={(value)=>{setPwCom(value)}}
                    />
                </InputSection>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        contents={"비밀번호 변경"}
                        disabled={btnChk}
                        func={() => {pwSetting()}}
                    />
                </BtnBox>
            </ContentsSection>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
        </>
    );
};

export default PwModify;