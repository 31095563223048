import useGet from "api/useGet";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BottomErrMsg } from "component/basic/popup";
import {ContentsSection, PageBody, PageSubHeader} from "component/app/items";
import { Header } from "component/elements/header";
import { weekArr } from "js/function";
import moment from "moment";

const ScheduleMain = (props) => {
    const [tab, setTab] =  useState("근무자");
    const [date,setDate] =  useState(moment().format('YYYY-MM-DD'));
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});
    const week = weekArr(date);

    const scheduleApi = useGet({
        url:`/schedule?date=${date}&tab=${tab}&company_id=${localStorage.getItem("companyId")}`,
        loginType:"login"
    });
    const scheduleData = scheduleApi?.data;

    function handleResize(){
        var totalSize = 0;
        let headerSize = document.querySelectorAll(".header")[0].clientHeight,
            subTitleSize = document.querySelectorAll(".pageSubHeaderBox")[0].clientHeight,
            weekTitleSize = document.querySelectorAll(".weekTitle")[0].clientHeight,
            selectYearBoxSize = document.querySelectorAll(".selectYearBox")[0].clientHeight,
            menuSize = document.querySelectorAll(".menu")[0].clientHeight,
            viewSize = document.documentElement.clientHeight;

        totalSize = viewSize - (headerSize + subTitleSize + menuSize)

        document.querySelectorAll(".pageBody")[0].style.height = `${totalSize}px`;
        document.querySelectorAll(".cardBox")[0].style.height = `${totalSize - selectYearBoxSize - weekTitleSize}px`;
    }

    useEffect(() => {
        handleResize()
    }, [scheduleData]);

    return (
        <>
            <Header
                addClass="pageMainBg"
                menuBtn={true}
                headTitle={scheduleData?.company_name}
                centerTitle={true}
            />
            <ContentsSection addClass="headerContents pageMainBg">
                <PageSubHeader
                    icon={<img className="icon" src="/assets/images/icon/schedule.svg"/>}
                    contents="스케줄"
                    menu={<div className="menu">
                        <div className={`menuButton ${tab === '근무자' ? 'active ' : ''}`} onClick={() => setTab('근무자')}>근무자 {scheduleData?.schedule_count?.work_count}</div>
                        <div className={`menuButton ${tab === '휴무자' ? 'active ' : ''}`} onClick={() => setTab('휴무자')}>휴무자 {scheduleData?.schedule_count?.close_count}</div>
                        <div className={`menuButton ${tab === '휴가자' ? 'active ' : ''}`} onClick={() => setTab('휴가자')}>휴가자 {scheduleData?.schedule_count?.dayoff_count}</div>
                        <div className={`menuButton ${tab === '결근자' ? 'active ' : ''}`} onClick={() => setTab('결근자')}>결근자 {scheduleData?.schedule_count?.absence_count}</div>
                    </div>}
                />
                <PageBody useWeek={true} week={week} setDate={setDate} selDate={date} dateClick={(selDate)=>{setDate(selDate)}}>
                    <div className="cardBox">
                        <div className="scheduleList_section">
                            {scheduleData?.is_confirm === 0 ? 
                                <div className="schedule_confirm_err">
                                    <img src="/assets/images/basic/err_icon.svg"/>
                                    해당 주간은 근무 스케줄이 확정되지 않았습니다.
                                </div>
                                :""
                            }
                            {scheduleData?.users && scheduleData?.users?.map((item,i)=>(
                                <div className="scheduleList_item" key={i}>
                                    <h1 className="scheduleList_item_title">
                                        <span>{item?.name}</span> {item?.department} {item?.position}
                                    </h1>
                                    <div className="work_scheduleData">
                                        <p className="work_scheduleData_name">근무현황</p>
                                        <p className="work_scheduleData_value">
                                            {item?.begin_time} ~ {item?.end_time}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </PageBody>
            </ContentsSection>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
        </>
    );
};

export default ScheduleMain;