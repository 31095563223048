import useGet from "api/useGet";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {ContentsSection, PageBody, PageSubHeader} from "component/app/items";
import { BtnItem } from "component/basic/btns";
import { Header } from "component/elements/header";
import moment from "moment";
import {comFormat} from "../../js/function";

const WorkHistory = (props) => {
    const navigate = useNavigate();

    const [month, setMonth] =  useState(moment().format("YYYY.MM"));
    const [menuPopup,setMenuPopup] =  useState('');

    const additionalApi = useGet({url:`/additional/pay?company_id=${localStorage.getItem("companyId")}&month=${moment(month, "YYYY.MM", true).format("YYYY-MM")}`, loginType:"login"});
    const additionalPays = additionalApi?.data?.additional_pays;

    function handleResize(){
        var totalSize = 0;
        let headerSize = document.querySelectorAll(".header")[0].clientHeight,
            subTitleSize = document.querySelectorAll(".pageSubHeaderBox")[0].clientHeight,
            subHeaderCardSize = document.querySelectorAll(".subHeaderCard")[0].clientHeight,
            selectYearBoxSize = document.querySelectorAll(".selectYearBox")[0].clientHeight,
            pageBtnSize = document.querySelectorAll(".pageBtn")[0].clientHeight,
            viewSize = document.documentElement.clientHeight;

        totalSize = viewSize - (headerSize + subTitleSize + pageBtnSize + subHeaderCardSize + 17)

        document.querySelectorAll(".pageBody")[0].style.height = `${totalSize}px`;
        document.querySelectorAll(".cardBox")[0].style.height = `${totalSize - selectYearBoxSize}px`;
    }

    useEffect(() => {
        handleResize()
    }, [additionalPays]);

    return (
        <>
            <Header
                addClass="pageMainBg"
                menuBtn={true}
                headTitle={additionalApi?.data?.company_name}
                centerTitle={true}
            />
            <ContentsSection addClass="headerContents pageMainBg">
                <PageSubHeader
                    addCardClass="subHeaderCard"
                    icon={<img className="icon" src="/assets/images/icon/additional.svg"/>}
                    contents="추가수당"
                    title={`${moment(month, "YYYY.MM", true).format("M")}월달 승인된 누적 추가수당`}
                    subTitle={`${additionalApi?.data?.accept_additional_pay ? comFormat(additionalApi?.data?.accept_additional_pay) : 0}원`}
                />
                <PageBody
                    useMonth={true}
                    month={month}
                    setMonth={setMonth}>
                    <div className="cardBox">
                        {additionalPays?.map((additionalPay) => (
                            <div className="card" style={{ cursor: "auto" }}>
                                <div className="cardTitleBox">
                                    <div className="title">{additionalPay?.date}</div>
                                    <div className="menuBox">
                                        {additionalPay?.status === 0 ? (<div className="categoryTag yellowTag">대기</div>) : (additionalPay?.status === 1 ? (<div className="categoryTag redTag">승인</div>) : (additionalPay?.status === 2 ? (<div className="categoryTag yellowTag">반려</div>) : (additionalPay?.status === 3 ? (<div className="categoryTag grayTag">취소</div>) : (''))))}
                                        <button onClick={() => { setMenuPopup((menuPopup && menuPopup === additionalPay?.id) ? '' : additionalPay?.id) }}><img src="/assets/images/basic/menu.svg"/></button>
                                        <button className={`menuPopup popover ${menuPopup === additionalPay?.id ? 'active' : ''}`} onClick={() => { navigate(`/additional/request/detail/${additionalPay?.id}`) }}>
                                            상세보기
                                        </button>
                                    </div>
                                </div>
                                <div className="cardContentsBox">
                                    <div className="item">
                                        <div className="label">추가수당 종류</div>
                                        <div className="contents">{additionalPay?.type}</div>
                                    </div>
                                    <div className="item">
                                        <div className="label">추가수당 금액</div>
                                        <div className="contents">{additionalPay?.pay ? comFormat(additionalPay?.pay) : 0}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </PageBody>
                <BtnItem
                    addClass=""
                    contents={"추가수당 신청"}
                    disabled={false}
                    func={() => {navigate("/additional/request")}}
                />
            </ContentsSection>
        </>
    );
};

export default WorkHistory;