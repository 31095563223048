import useGet from "api/useGet";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {ContentsSection, PageBody, PageSubHeader} from "component/app/items";
import { BtnItem } from "component/basic/btns";
import { Header } from "component/elements/header";
import moment from "moment";
import 'moment/locale/ko';
const WorkHistory = (props) => {
    const navigate = useNavigate();

    const [month, setMonth] =  useState(moment().format("YYYY.MM"));

    const dayOffApi = useGet({url:`/day/off?company_id=${localStorage.getItem("companyId")}&month=${moment(month, "YYYY.MM").format("YYYY-MM")}`, loginType:"login"});
    const dayOffs = dayOffApi?.data?.day_offs;

    function handleResize(){
        var totalSize = 0;
        let headerSize = document.querySelectorAll(".header")[0].clientHeight,
            subTitleSize = document.querySelectorAll(".pageSubHeaderBox")[0].clientHeight,
            subHeaderCardSize = document.querySelectorAll(".subHeaderCard")[0].clientHeight,
            selectYearBoxSize = document.querySelectorAll(".selectYearBox")[0].clientHeight,
            pageBtnSize = document.querySelectorAll(".pageBtn")[0].clientHeight,
            viewSize = document.documentElement.clientHeight;

        totalSize = viewSize - (headerSize + subTitleSize + pageBtnSize + subHeaderCardSize + 17)

        document.querySelectorAll(".pageBody")[0].style.height = `${totalSize}px`;
        document.querySelectorAll(".cardBox")[0].style.height = `${totalSize - selectYearBoxSize}px`;
    }

    useEffect(() => {
        handleResize()
    }, [dayOffs]);

    return (
        <>
            <Header
                addClass="pageMainBg"
                menuBtn={true}
                headTitle={dayOffApi?.data?.company_name}
                centerTitle={true}
            />
            <ContentsSection addClass="headerContents pageMainBg">
                <PageSubHeader
                    addCardClass="subHeaderCard"
                    icon={<img className="icon" src="/assets/images/icon/dayoff.svg"/>}
                    contents="휴가신청"
                    title={<div className="titleBox">
                        <div className="title">남은 연차일 수</div>
                        <div className="subTitle">(회계일 기준)</div>
                    </div>}
                    subTitle={`${dayOffApi?.data?.remain_day_off ? dayOffApi?.data?.remain_day_off : 0}${dayOffApi?.data?.day_off_type === 'full' ? '일' : '시간'}`}
                    title2={<div className="titleBox">
                        <div className="title">부여된 연차일 수</div>
                        <div className="subTitle">(회계일 기준)</div>
                    </div>}
                    subTitle2={`${dayOffApi?.data?.receive_day_off ? dayOffApi?.data?.receive_day_off : 0}${dayOffApi?.data?.day_off_type === 'full' ? '일' : '시간'}`}
                />
                <PageBody
                    useMonth={true}
                    month={month}
                    setMonth={setMonth}>
                    <div className="cardBox">
                        {dayOffs?.map((dayOff) => (
                            <div className="card" onClick={() => navigate(`/dayoff/detail/${dayOff?.id}`)}>
                                <div className="cardTitleBox">
                                    <div className="title">{moment(dayOff?.begin_date).format("M.D")} ({moment(dayOff?.begin_date).format('dddd').replace('요일', '')}) {dayOff?.end_date && (" - " + (moment(dayOff?.end_date).format("M.D"))) + " (" + moment(dayOff?.end_date).format('dddd').replace('요일', '') + ")"}</div>
                                    <div className="menuBox">
                                        {dayOff?.status === 0 ? (<div className="categoryTag yellowTag">대기</div>) : (dayOff?.status === 1 ? (<div className="categoryTag redTag">승인</div>) : (dayOff?.status === 2 ? (<div className="categoryTag yellowTag">반려</div>) : (dayOff?.status === 3 ? (<div className="categoryTag grayTag">취소</div>) : (''))))}
                                    </div>
                                </div>
                                <div className="cardContentsBox">
                                    <div className="largeItem">
                                        <div className="label">연차 형태</div>
                                        {dayOff?.day_off_details?.map((dayOffDetail) => (
                                            <div className="contents">
                                                <div className="title">{dayOffDetail?.is_unpaid === 1 ? '무급 ' : ''} {dayOffDetail?.type === 'day' ? '연차 1일' : (dayOffDetail?.type === 'morning') ? '오전반차' : ((dayOffDetail?.type === 'afternoon' ? '오후반차' : ''))}</div>
                                                <div className="text">{moment(dayOffDetail?.date).format("YYYY.M.D")} ({moment(dayOffDetail?.date).format('dddd').replace('요일', '')}) {dayOffDetail?.begin_time && (dayOffDetail?.begin_time + ' ~ ' + dayOffDetail?.end_time)}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </PageBody>
                <BtnItem
                    addClass=""
                    contents={"휴가 신청"}
                    disabled={false}
                    func={() => {navigate("/dayoff/request")}}
                />
            </ContentsSection>
        </>
    );
};

export default WorkHistory;