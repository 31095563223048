import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BottomErrMsg } from "component/basic/popup";
import { ContentsSection, InputSection } from "component/app/items";
import {BottomPopup, CustomSelect, TextAreaItem,} from "component/basic/formItems";
import { BtnBox, BtnItem } from "component/basic/btns";
import { Header } from "component/elements/header";

const Request = (props) => {
    const navigate = useNavigate();

    const [completePopup, setCompletePopup] =  useState(false);
    const [documentTypeId, setDocumentTypeId] =  useState('');
    const [memo, setMemo] =  useState('');

    const [btnChk,setBtnChk] =  useState(true);
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    const documentApi = useGet({url:`/document/type?company_id=${localStorage.getItem("companyId")}`, loginType:"login"});
    const documentData = documentApi?.data?.document_types;

    function handleSubmit(){
        let formData = new FormData();
        formData.append("company_id", localStorage.getItem("companyId"));
        formData.append("document_type_id", documentTypeId);
        formData.append("memo", memo);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: "/document/request",
            loginType: "login",
            success: (data) => {
                setCompletePopup(true)
            },
            err: (data) => {
                if(data.data || data.alert){
                    setBottomMsgData({
                        text : data.alert||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    }

    useEffect(() => {
        if (documentTypeId !== "" && memo !== "") {
            setBtnChk(false)
        } else {
            setBtnChk(true)
        }
    }, [documentTypeId, memo]);

    return (
        <>
            <Header
                pageBack={true}
                func={() => {
                    navigate(-1)
                }}
                headTitle="문서발급 신청"
                centerTitle={true}
            />
            <ContentsSection addClass="headerContents btnContents">
                <InputSection addClass="registerSection">
                    <CustomSelect
                        inputTitle="문서 형태"
                        placeholder="선택"
                        value={documentTypeId}
                        func={(name,val,subVal) => {
                            setDocumentTypeId(val);
                        }}
                        disabled={false}
                        valKey="id"
                        nameKey="title"
                        options={documentData ? documentData : []}
                    />
                    <TextAreaItem
                        inputTitle="메모"
                        inputName=""
                        placeholder="내용을 입력해주세요"
                        max={500}
                        maxChk={false}
                        value={memo}
                        func={(value) => setMemo(value)}
                    />
                </InputSection>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        contents={"작성하기"}
                        disabled={btnChk}
                        func={() => { handleSubmit() }}
                    />
                </BtnBox>
                <BottomPopup
                    open={completePopup}
                    title="문서발급 신청 완료"
                    subTitle={<div>문서발급 신청이 완료되었습니다. <br/>신청 결과는 관리자 승인 후 확인 가능합니다.</div>}
                    btn1Text="확인"
                    btn1Func={() => { navigate(`/document`); }}
                />
            </ContentsSection>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
        </>
    );
};

export default Request;