import useGet from "api/useGet";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {ContentsSection, PageBody, PageSubHeader, InputSection} from "component/app/items";
import { BtnItem } from "component/basic/btns";
import { Header } from "component/elements/header";
import moment from "moment";
import 'moment/locale/ko';
import {BottomPopup, ChkBox, TextAreaItem} from "../../component/basic/formItems";
import * as fatchSet from "../../api/api";
import {BottomErrMsg} from "../../component/basic/popup";
const Todo = (props) => {
    const navigate = useNavigate();

    const [modal, setModal] =  useState(false);
    const [todoKey, setTodoKey] =  useState('');

    const [month, setMonth] =  useState(moment().format("YYYY.MM"));
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});
    const [mutate, setMutate] =  useState(false);

    const todoApi = useGet({url:`/todolist?company_id=${localStorage.getItem("companyId")}&begin_month=${moment(month, "YYYY.MM").format("YYYY-MM")}`, loginType:"login", mutate: mutate});
    const todolists = todoApi?.data?.todolists;

    function handleResize(){
        var totalSize = 0;
        let headerSize = document.querySelectorAll(".header")[0].clientHeight,
            subTitleSize = document.querySelectorAll(".pageSubHeaderBox")[0].clientHeight,
            selectYearBoxSize = document.querySelectorAll(".selectYearBox")[0].clientHeight,
            viewSize = document.documentElement.clientHeight;

        totalSize = viewSize - (headerSize + subTitleSize)
        if (todoApi?.data?.is_manage_permission === 1) {
            let pageBtnSize = document.querySelectorAll(".pageBtn")[0].clientHeight;
            totalSize -= pageBtnSize;
        }

        document.querySelectorAll(".pageBody")[0].style.height = `${totalSize}px`;
        document.querySelectorAll(".cardBox")[0].style.height = `${totalSize - selectYearBoxSize}px`;
    }

    useEffect(() => {
        if (todoApi?.data?.is_manage_permission) {
            handleResize()
        }
    }, [todoApi?.data?.is_manage_permission]);

    function handleStatus(id){
        let formData = new FormData();
        formData.append("company_id", localStorage.getItem("companyId"));
        formData.append("todolist_id", id);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: "/todolist/check",
            loginType: "login",
            success: (data) => {
                setMutate(!mutate)
            },
            err: (data) => {
                if(data.data || data.alert){
                    setBottomMsgData({
                        text : data.alert||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    }

    return (
        <>
            <Header
                addClass="pageMainBg"
                menuBtn={true}
                headTitle={todoApi?.data?.company_name}
                centerTitle={true}
            />
            <ContentsSection addClass="headerContents pageMainBg">
                <PageSubHeader
                    addCardClass="subHeaderCard"
                    icon={<img className="icon" src="/assets/images/icon/todo.svg"/>}
                    contents="할 일"
                />
                <PageBody
                    useMonth={true}
                    month={month}
                    setMonth={setMonth}>
                    <div className="cardBox">
                        {todolists?.map((todolist, todolistKey) => (
                            <>
                                {(todolistKey === 0 || todolist?.date !== todolists[todolistKey - 1]?.date) && (
                                    <div className="todoDateLabel">{todolist?.date} ({moment(todolist?.date).format('dddd').replace('요일', '')})</div>
                                )}
                                <div className={`card todo ${todolist?.status === 1 ? 'active' : ''}`} onClick={(e) => { if (e.target.tagName !== 'LABEL' && e.target.tagName !== 'INPUT') { setModal(true); setTodoKey(todolistKey); } }}>
                                    <div>
                                        <ChkBox
                                            addClass="allChkItem"
                                            func={(e) => { e.preventDefault(); handleStatus(todolist?.id) }}
                                            checkedType={todolist?.status === 1}
                                            label=""

                                        />
                                    </div>
                                    <div className={`contentsBox ${todolist?.status === 1 ? 'active' : ''}`}>
                                        <div className="title">{todolist?.title}</div>
                                        <div className="contents">{todolist?.date} ({moment(todolist?.date).format('dddd').replace('요일', '')})</div>
                                    </div>
                                    <div className="alarmBox">
                                        {todolist?.use_alarm === 1 && (
                                            <>
                                                <img src="/assets/images/icon/alarm.svg"/>
                                                {moment(todolist?.alarm_date).format("HH:mm")}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </>
                        ))}
                    </div>
                </PageBody>
                {todoApi?.data?.is_manage_permission === 1 && (
                    <BtnItem
                        addClass=""
                        contents="할 일 등록"
                        disabled={false}
                        func={() => {navigate("/todo/register")}}
                    />
                )}
                <BottomPopup
                    open={modal}
                    contents={
                        <ContentsSection addClass="btnContents">
                            <InputSection addClass="registerSection mt-20">
                                <div className="pageSubHeaderTitle">나의 할 일</div>
                                {todolists?.length > 0 && (
                                    <div className="cardBox">
                                        <div className={`card modal todo ${todolists[todoKey]?.status === 1 ? 'active' : ''}`} style={{ cursor: "auto" }}>
                                            <div>
                                                <ChkBox
                                                    addClass="allChkItem"
                                                    func={(e) => { handleStatus(todolists[todoKey]?.id) }}
                                                    checkedType={todolists[todoKey]?.status === 1}
                                                    label=""
                                                />
                                            </div>
                                            <div className={`contentsBox ${todolists[todoKey]?.status === 1 ? 'active' : ''}`}>
                                                <div className="title">{todolists[todoKey]?.title}</div>
                                                <div className="contents">{todolists[todoKey]?.date} ({moment(todolists[todoKey]?.date).format('dddd').replace('요일', '')})</div>
                                            </div>
                                            <div className="alarmBox">
                                                {(todolists[todoKey]?.use_alarm === 1) && (
                                                    <>
                                                        <img src="/assets/images/icon/alarm.svg"/>
                                                        {moment(todolists[todoKey]?.alarm_date).format("HH:mm")}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <TextAreaItem
                                    inputTitle="내용"
                                    inputName=""
                                    placeholder="내용을 입력해주세요"
                                    disabled={true}
                                    max={500}
                                    maxChk={false}
                                    value={todolists?.length > 0 ? todolists[todoKey]?.contents : ""}
                                />
                            </InputSection>
                        </ContentsSection>
                    }
                    btn1Text="닫기"
                    btn1Func={() => { setModal(false) }}
                    btn2Text={todoApi?.data?.is_manage_permission === 1 ? '수정하기' : null}
                    btn2Func={() => { navigate(`/todo/modify/${todolists[todoKey]?.id}`) }}
                />
            </ContentsSection>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
        </>
    );
};

export default Todo;