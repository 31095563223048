import { Fragment } from "react";
import { useNavigate } from "react-router-dom";

function Header(headerData){
    let navigate = useNavigate();

    return (
        <div className="header">
            <div className={`headerSection ${headerData.addClass ? headerData.addClass : ""}`}>
                {!headerData.pageBack || headerData.pageBack === "" ? "" : <button type="button" className="btnBack btnHeaderLeft" onClick={(e) => headerData.func(e)}><img src="/assets/images/basic/back.svg"/></button>}
                {headerData.logo ? <button type="button" className="btnLogo btnHeaderLeft" onClick={(e) => navigate("/")}><img src="/assets/images/app/logo_min.svg"/></button> : ""}
                {headerData.carNumber ? <div className="headCarNumberBox btnHeaderLeft"><p className="headCarNumber">{headerData.carNumber}</p></div> : ""}
                {headerData.headTitle ? <p className={`headTitle ${headerData.centerTitle ? "centerTitle" : ""} ${headerData.titleClass ? headerData.titleClass : ""}`} dangerouslySetInnerHTML={{__html:headerData.headTitle}}/> : ""}
                {headerData.rightBtns ? 
                    <div className="headerInfoBox btnHeaderRight">
                        {headerData.rightBtnsArr.map((item, i) => (
                            <Fragment key={i}>
                                {item.type !== "img" ?
                                <button type="button" className={`btnHeaderRightItem ${item.addClass ? item.addClass : ""}`} onClick={(e) => {item.func()}} dangerouslySetInnerHTML={{__html:item.contents}}></button>
                                :
                                <button type="button" className={`btnHeaderRightItem ${item.addClass ? item.addClass : ""}`} onClick={(e) => {item.func()}}><img src={item.contents}/></button>
                                }
                            </Fragment>
                        ))}
                    </div>
                    : ""
                }
                {headerData.alarmBtn ? <button type="button" className={`btnAlarmLink btnHeaderRight ${headerData.alarmType ? "active" : ""}`} onClick={(e) => {navigate("/alarm")}}><img src={`/assets/images/icon/alarm_icon_off.svg`}/></button> : ""}
                {headerData.homeBtn ? <button type="button" className="btnHomeLink btnHeaderRight" onClick={(e) => {navigate("/")}}><img src="/assets/images/icon/home_icon.svg"/></button> : ""}
                {headerData.menuBtn ? <button type="button" className="btnHomeLink btnHeaderRight" onClick={(e) => { window.rightOpen() }}><img src="/assets/images/icon/menu.png"/></button> : ""}
                {headerData.pageClose ? <button type="button" className="btnClose btnHeaderRight" onClick={(e) => {headerData.closeFunc(e)}}><img src="/assets/images/basic/close_w.svg"/></button> : ""}
                {headerData.children}
            </div>
        </div>
    );
}

export {Header};