export function FatchApi(fatchData){
    let headersData = (fatchData.loginType == "login" || fatchData.loginType == "sessionLogin") && fatchData.type == "POST" ? {
        method: `${fatchData.type}`,
        headers: {
            'Authorization': 'Bearer ' + `${fatchData.loginType == "login" ? localStorage.getItem("token") : sessionStorage.getItem("token")}`,
            'Accept' : 'application/json'
        },
        body: fatchData.formDataItem
    } : fatchData.type == "POST" ? {
        method: `${fatchData.type}`,
        headers: {
            'Accept' : 'application/json'
        },
        body: fatchData.formDataItem
    } : (fatchData.loginType == "login" || fatchData.loginType == "sessionLogin") && fatchData.type == "DELETE" ? {
        method: `${fatchData.type}`,
        headers: {
            'Authorization': 'Bearer ' + `${fatchData.loginType == "login" ? localStorage.getItem("token") : sessionStorage.getItem("token")}`,
            'Accept' : 'application/json'
        },
        body: fatchData.formDataItem
    } : fatchData.type == "DELETE" ? {
        method: `${fatchData.type}`,
        headers: {
            'Accept' : 'application/json'
        },
        body: fatchData.formDataItem
    } : (fatchData.loginType == "login" || fatchData.loginType == "sessionLogin") ? {
        method: `${fatchData.type}`,
        headers: {
            'Authorization': 'Bearer ' + `${fatchData.loginType == "login" ? localStorage.getItem("token") : sessionStorage.getItem("token")}`,
            'Accept' : 'application/json'
        }
    } : {
        method: `${fatchData.type}`,
        headers: {
            'Accept' : 'application/json'
        }
    }

    fetch(`${process.env.REACT_APP_API_URL}${fatchData.url}`, headersData).then(function(response) {
        if(process.env.REACT_APP_TYPE == "live"){
            if(response?.status !== 200){
                if (response?.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("refresh_token");
                    window.location.href = `/member/login`;
                } else {
                    window.location.href = `/pageErr/${response?.status}`;
                }
            }
        }
        return response.json();
    }).then(function(data) {
        if (data.success == true) {
            fatchData.success(data);
        }else{
            fatchData.err(data);
        }
    });

    return 
}