import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BottomErrMsg, Popup } from "component/basic/popup";
import {BoardCard, ContentsSection, InputSection, PageBody, PageSubHeader, PageTitle} from "component/app/items";
import {BottomPopup, InputItemBox} from "component/basic/formItems";
import { BtnItem } from "component/basic/btns";
import { CompanyBtn, SnsBtn } from "component/member/items";
import { Header } from "component/elements/header";
import { NotItems } from "component/basic/notItems";
import { weekArr } from "js/function";
import moment from "moment";
import modify from "../board/Modify";

const WorkHistory = (props) => {
    const navigate = useNavigate();
    const varUA = navigator.userAgent;

    const [update, setUpdate] =  useState(1);
    const [menuPopup,setMenuPopup] =  useState('');
    const [beginPopup,setBeginPopup] =  useState('');
    const [endPopup,setEndPopup] =  useState('');
    const [modifyCancelPopup,setModifyCancelPopup] =  useState('');
    const [modifyAlertPopup,setModifyAlertPopup] =  useState('');
    const [workHistoryId,setWorkHistoryId] =  useState('');
    const [date,setDate] =  useState(moment().format('YYYY-MM-DD'));
    const [salaryCloseDate,setSalaryCloseDate] =  useState('');
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});
    const week = weekArr(date);

    const companyApi = useGet({
        url:week && week?.length > 6 ? `/work/history?company_id=${localStorage.getItem("companyId")}&begin_date=${week[0]?.fullDate}&end_date=${week[6]?.fullDate}&update=${update}` : null,
        loginType:"login"
    });
    const data = companyApi?.data;
    const workHistoryData = companyApi?.data?.work_histories;

    function handleResize(){
        var totalSize = 0;
        let headerSize = document.querySelectorAll(".header")[0].clientHeight,
            subTitleSize = document.querySelectorAll(".pageSubHeaderBox")[0].clientHeight,
            weekTitleSize = document.querySelectorAll(".weekTitle")[0].clientHeight,
            selectYearBoxSize = document.querySelectorAll(".selectYearBox")[0].clientHeight,
            viewSize = document.documentElement.clientHeight;

        totalSize = viewSize - (headerSize + subTitleSize)

        if (data?.is_auto === 0) {
            let pageBtnSize = document.querySelectorAll(".pageBtn")[0].clientHeight
            totalSize -= pageBtnSize
        }

        document.querySelectorAll(".pageBody")[0].style.height = `${totalSize}px`;
        document.querySelectorAll(".cardBox")[0].style.height = `${totalSize - selectYearBoxSize - weekTitleSize}px`;
    }

    useEffect(() => {
        if (data) {
            handleResize()

            if (data?.salary_history_info?.max_month) {
                setSalaryCloseDate(data?.salary_history_info?.max_month)
            }
        }
    }, [workHistoryData]);

    useEffect(() => {
        checkForAccess();
    }, []);

    const checkForAccess = () => {
        if (localStorage.getItem("token") !== null) {
            if (varUA.match("waveone/Android") !== null) {
                window.waveone.autoLogin(localStorage.getItem("token"));
            } else if (varUA.match("waveone/iOS") !== null) {
                window.webkit.messageHandlers.autoLoginHandler.postMessage(localStorage.getItem("token"));
            }
        }
    };

    function handleBegin() {
        let formData = new FormData();
        formData.append("company_id", localStorage.getItem("companyId"));

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            loginType:"login",
            url: `/work/start`,
            success: (data) => {
                setBeginPopup(false)
                setUpdate(update + 1)
            },
            err: (data) => {
                if(data.data || data.alert){
                    setBottomMsgData({
                        text : data.alert||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    }

    function handleEnd() {
        let formData = new FormData();
        formData.append("company_id", localStorage.getItem("companyId"));

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            loginType:"login",
            url: `/work/end`,
            success: (data) => {
                setEndPopup(false)
                setUpdate(update + 1)
            },
            err: (data) => {
                if(data.data || data.alert){
                    setBottomMsgData({
                        text : data.alert||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    }

    function handleModifyCancel() {
        let formData = new FormData();
        formData.append("company_id", localStorage.getItem("companyId"));
        formData.append("work_history_id", workHistoryId);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            loginType:"login",
            url: `/work/modify/cancel`,
            success: (data) => {
                setModifyCancelPopup(false)
                setWorkHistoryId('')
                setMenuPopup('')
                setUpdate(update + 1)
            },
            err: (data) => {
                if(data.data || data.alert){
                    setBottomMsgData({
                        text : data.alert||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    }

    return (
        <>
            <Header
                addClass="pageMainBg"
                menuBtn={true}
                headTitle={companyApi?.data?.company_name}
                centerTitle={true}
            />
            <ContentsSection addClass="headerContents pageMainBg">
                <PageSubHeader
                    icon={<img className="icon" src="/assets/images/icon/workHistory.svg"/>}
                    contents="출퇴근 기록"
                />
                <PageBody useWeek={true} week={week} setDate={setDate}>
                    <div className="cardBox">
                        {workHistoryData?.map((workHistory) => (
                            <div className="card" style={{ cursor: "auto" }}>
                                <div className="cardTitleBox">
                                    <div className="title">{workHistory?.date}</div>
                                    <div className="menuBox">
                                        {workHistory?.is_absence === 1 ? (<div className="categoryTag redTag">결근</div>) : (workHistory?.is_late === 1 ? (<div className="categoryTag yellowTag">지각</div>) : (workHistory?.is_day_off === 1 ? (<div className="categoryTag blueTag">연차</div>) : (<div className="categoryTag">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>)))}
                                        {workHistory?.modify_request === 1 && (workHistory?.request_status === 1 ? (<div className="categoryTag blueTag">변경요청 승인</div>) : (workHistory?.request_status === 2 ? (<div className="categoryTag redTag">변경요청 반려</div>) : (<div className="categoryTag yellowTag">변경요청</div>)))}
                                        {!workHistory?.salary_id && (
                                            <>
                                                <button onClick={() => { setMenuPopup((menuPopup && menuPopup === workHistory?.id) ? '' : workHistory?.id) }}><img src="/assets/images/basic/menu.svg"/></button>
                                                <button className={`menuPopup work ${workHistory?.modify_request >= 0 ? 'modify' : ''} ${workHistory?.request_status > 0 ? 'result' : ''} popover ${menuPopup === workHistory?.id ? 'active' : ''}`}
                                                        onClick={() => {
                                                            if (workHistory?.modify_request === 0) {
                                                                if ((salaryCloseDate !== '') && (moment(salaryCloseDate) >= moment())) {
                                                                    console.log(salaryCloseDate)
                                                                    setModifyAlertPopup(true)
                                                                } else {
                                                                    console.log(salaryCloseDate)
                                                                    console.log(workHistory?.issue_date)
                                                                    navigate(`/workHistory/modify/request/${workHistory?.id}`)
                                                                }
                                                            } else if (workHistory?.request_status === 1 || workHistory?.request_status === 2) {
                                                                navigate(`/workHistory/modify/detail/${workHistory?.id}`)
                                                            } else {
                                                                setModifyCancelPopup(true);
                                                                setWorkHistoryId(workHistory?.id)
                                                            }
                                                        }}
                                                >
                                                    {workHistory?.modify_request === 0 ? '변경 요청' : (workHistory?.request_status === 1 || workHistory?.request_status === 2) ? ('상세보기') : ('변경 요청 취소')}
                                                </button>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="cardContentsBox">
                                    <div className="item">
                                        <div className="label">계약 근무시간</div>
                                        <div className="contents">{workHistory?.contract_begin_time} - {workHistory?.contract_end_time}</div>
                                    </div>
                                    <div className="item">
                                        <div className="label">실제 근무시간</div>
                                        <div className="contents">
                                            {workHistory?.is_absence === 1 ? (<div className="dangerTextColor">{workHistory?.contract_begin_time} - {workHistory?.contract_end_time}</div>) :
                                            (workHistory?.is_late === 1 ? (<div className={'flex'}><div className="dangerTextColor margin-right-5">{workHistory?.real_begin_time ? workHistory?.real_begin_time : workHistory?.contract_begin_time}</div> - {workHistory?.real_end_time ? (workHistory?.real_end_time > workHistory?.contract_end_time ? <div className={'dangerTextColor margin-left-5'}>{workHistory?.real_end_time}</div> : workHistory?.real_end_time): workHistory?.contract_end_time}</div>) :
                                            (workHistory?.is_day_off === 1 ? (<div className="">{workHistory?.contract_begin_time} - {workHistory?.contract_end_time}</div>) :
                                            (<div className={'flex'}>{workHistory?.real_begin_time} - {workHistory?.real_end_time ? (workHistory?.real_end_time > workHistory?.contract_end_time ? <div className={'dangerTextColor margin-left-5'}>{workHistory?.real_end_time}</div> : workHistory?.real_end_time): workHistory?.contract_end_time}</div>)))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <BottomPopup
                        open={modifyAlertPopup}
                        title="변경 불가능한 근무"
                        subTitle="급여 마감이 진행된 일자의 출퇴근 일정은 수정하실 수 없습니다."
                        btn1Text="확인"
                        btn1Func={() => { setModifyAlertPopup(false) }}
                    />
                    <BottomPopup
                        open={beginPopup}
                        title="출근하시겠습니까?"
                        subTitle="출근한 이후 데이터의 변경은 출퇴근 일정 수정에서 변경하실 수 있습니다."
                        btn1Text="취소"
                        btn2Text="출근하기"
                        btn1Func={() => { setBeginPopup(false) }}
                        btn2Func={() => { handleBegin() }}
                    />
                    <BottomPopup
                        open={endPopup}
                        title="퇴근하시겠습니까?"
                        subTitle="퇴근한 이후 데이터의 변경은 출퇴근 일정 수정에서 변경하실 수 있습니다."
                        btn1Text="취소"
                        btn2Text="퇴근하기"
                        btn1Func={() => { setEndPopup(false) }}
                        btn2Func={() => { handleEnd() }}
                    />
                    <BottomPopup
                        open={modifyCancelPopup}
                        title="변경요청을 취소하시겠습니까?"
                        subTitle="변경 요청을 지금 취소하실 경우 기존에 요청하신 모든 정보가 삭제됩니다."
                        btn1Text="취소"
                        btn2Text="요청 취소"
                        btn1Func={() => { setModifyCancelPopup(false) }}
                        btn2Func={() => { handleModifyCancel() }}
                    />
                </PageBody>
                {data?.is_auto === 0 && (
                    <BtnItem
                        addClass="col2 workHistory"
                        useWorkHistory={true}
                        contents={`<div class="workButton">${data?.work_real_begin_time ? '출근 완료' : '출근'}<div class="sub">${data?.work_real_begin_time ? '' : '예정 '}출근시간 ${data?.work_schedule_begin_time ? data?.work_schedule_begin_time : ''}</div></div>`}
                        btn2Contents={`<div class="workButton">${data?.work_real_end_time ? '퇴근 완료' : '퇴근'}<div class="sub">${data?.work_real_end_time ? '' : '예정 '}퇴근시간 ${data?.work_schedule_end_time ? data?.work_schedule_end_time : ''}</div></div>`}
                        disabled={data?.work_real_begin_time}
                        btn2Disabled={data?.work_real_end_time}
                        func={() => { setBeginPopup(true) }}
                        btn2Func={() => { setEndPopup(true) }}
                    />
                )}
            </ContentsSection>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
        </>
    );
};

export default WorkHistory;