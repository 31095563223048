import useGet from "api/useGet";
import React, {useState, useEffect, useRef} from "react";
import { useNavigate } from "react-router-dom";
import {ContentsSection, InputSection, PageBody, PageSubHeader} from "component/app/items";
import { Header } from "component/elements/header";
import 'moment/locale/ko';
import {comFormat, pageBottomChk} from "../../js/function";
import moment from "moment/moment";
import {BottomPopup, ChkBox, TextAreaItem} from "../../component/basic/formItems";
import * as fatchSet from "../../api/api";
import {BottomErrMsg} from "../../component/basic/popup";
const TempWorker = (props) => {
    const navigate = useNavigate();

    const [mutate, setMutate] =  useState(false);
    const [modal, setModal] =  useState(false);
    const [modalId, setModalId] =  useState('');
    const [modalType, setModalType] =  useState('');
    const [modalStatus, setModalStatus] =  useState('');
    const [modalMemo, setModalMemo] =  useState('');
    const [page, setPage] =  useState(1);
    const [tab, setTab] =  useState("receipt");
    const [itemList, setItemList] =  useState([]);
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    const lastPage =  useRef(false)
    const scrollRef = useRef(null);

    const workflowApi = useGet({url:`/workflow?company_id=${localStorage.getItem("companyId")}&page=${page}&type=${tab}`, loginType:"login", mutate: mutate});
    const workflowData = workflowApi?.data?.workflows;

    useEffect(() => {
        if (workflowData?.data && workflowData?.data.length > 0) {
            if (page === 1) {
                setItemList([...workflowData?.data]);
            } else {
                setItemList(prev => [...prev, ...workflowData?.data]);
            }
        } else {
            setItemList([]);
        }

        lastPage.current = workflowData?.current_page >= workflowData?.last_page;
        handleResize()
    }, [workflowData]);

    useEffect(() => {
        if (scrollRef.current) {
            const scrollRefCurrent = scrollRef.current
            scrollRefCurrent.removeEventListener("scroll", handleScroll);
            scrollRefCurrent.addEventListener("scroll", handleScroll);
            return () => {
                scrollRefCurrent.removeEventListener("scroll", handleScroll);
            };
        }
    }, [scrollRef]);

    function handleSubmit(){
        let url = '';
        if (modalType === 'work_modify') {
            url = '/work/request/confirm';
        } else if (modalType === 'additional_pay_request') {
            url = '/additional/pay/request/confirm';
        } else if (modalType === 'day_off_request') {
            url = '/day/off/request/confirm';
        } else if (modalType === 'document_request') {
            url = '/document/request/confirm';
        }

        if (url === '') {
            setBottomMsgData({
                text : "잘못된 접근입니다",
                chk : bottomMsgData.chk + 1
            });
            return false
        }

        let formData = new FormData();
        formData.append("company_id", localStorage.getItem("companyId"));
        formData.append("request_id", modalId);
        formData.append("status", modalStatus);
        formData.append("admin_message", modalMemo);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: url,
            loginType: "login",
            success: (data) => {
                setMutate(!mutate)
                setBottomMsgData({
                    text : "처리되었습니다",
                    chk : bottomMsgData.chk + 1
                });
                setModal(false)
            },
            err: (data) => {
                if(data.data || data.alert){
                    setBottomMsgData({
                        text : data.alert||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    }

    function handleScroll(){
        if (pageBottomChk(".cardBox") && !lastPage.current){
            setPage(page + 1);
        }
    };

    function handleResize(){
        var totalSize = 0;
        let headerSize = document.querySelectorAll(".header")[0].clientHeight,
            subTitleSize = document.querySelectorAll(".pageSubHeaderBox")[0].clientHeight,
            menuSize = document.querySelectorAll(".menu")[0].clientHeight,
            viewSize = document.documentElement.clientHeight;

        totalSize = viewSize - (headerSize + subTitleSize + menuSize)

        document.querySelectorAll(".pageBody")[0].style.height = `${totalSize}px`;
        document.querySelectorAll(".cardBox")[0].style.height = `${totalSize}px`;
    }

    return (
        <>
            <Header
                addClass="pageMainBg"
                menuBtn={true}
                headTitle={workflowApi?.data?.company_name}
                centerTitle={true}
            />
            <ContentsSection addClass="headerContents pageMainBg">
                <PageSubHeader
                    addCardClass="subHeaderCard"
                    icon={<img className="icon" src="/assets/images/icon/workflow.svg"/>}
                    contents="워크플로우"
                    menu={<div className="menu">
                        <div className={`menuButton ${tab === 'receipt' ? 'active ' : ''}`} onClick={() => setTab('receipt')}>대기 {workflowApi?.data?.wait_count}</div>
                        <div className={`menuButton ${tab === 'complete' ? 'active ' : ''}`} onClick={() => setTab('complete')}>완료 {workflowApi?.data?.complete_count}
                            <img className="labelPopupButton" src="/assets/images/basic/question.svg"/>
                            <div className="labelPopupBox">
                                <img src="/assets/images/basic/popover.svg" className="labelPopupArrow"/>
                                <button className="labelPopup popover">
                                    완료된 워크플로우는 최근 1개월까지 노출됩니다.
                                </button>
                            </div>
                        </div>
                    </div>}
                />
                <PageBody>
                    <div className="cardBox" ref={scrollRef}>
                        {itemList?.map((workflow) => (
                            <>
                                <div className="card bottomButton" style={{ cursor: "auto" }}>
                                    <div className="cardTitleBox">
                                        <div className="title tag">
                                            {workflow?.type === 'work_modify' && (<div className="categoryTag redTag m-0">시간변경 요청</div>)}
                                            {workflow?.type === 'additional_pay_request' && (<div className="categoryTag blueTag m-0">추가수당 신청</div>)}
                                            {workflow?.type === 'day_off_request' && (<div className="categoryTag greenTag m-0">휴가신청</div>)}
                                            {workflow?.type === 'document_request' && (<div className="categoryTag grayTag m-0">문서발급 요청</div>)}
                                            <div className="name">{workflow?.name} <span className="sub">{workflow?.department_name} {workflow?.position_name}</span></div>
                                        </div>
                                    </div>
                                    <div className="cardContentsBox">
                                        {workflow?.type === 'work_modify' && (<div className="item">
                                            <div className="label">요청일시</div>
                                            <div className="contents">{moment(workflow?.date).format('YYYY.MM.DD')} ({moment(workflow?.date).format('dddd').replace('요일', '')}) {workflow?.request_begin_time} - {workflow?.request_end_time}</div>
                                        </div>)}
                                        {workflow?.type === 'additional_pay_request' && (<>
                                            <div className="item">
                                                <div className="label">추가 수당 종류</div>
                                                <div className="contents">{workflow?.additional_pay_type}</div>
                                            </div>
                                            <div className="item">
                                                <div className="label">추가 수당 금액</div>
                                                <div className="contents">{comFormat(workflow?.additional_pay_price)}원</div>
                                            </div>
                                        </>)}
                                        {workflow?.type === 'day_off_request' && (
                                            <div className="largeItem">
                                                <div className="label">연차 형태</div>
                                                {workflow?.day_off_details?.map((dayOffDetail) => (
                                                    <div className="contents">
                                                        <div className="title">{dayOffDetail?.type === 'day' ? '연차 1일' : (dayOffDetail?.type === 'morning') ? '오전반차' : ((dayOffDetail?.type === 'afternoon' ? '오후반차' : ''))}</div>
                                                        <div className="text">{moment(dayOffDetail?.date).format("YYYY.M.D")} ({moment(dayOffDetail?.date).format('dddd').replace('요일', '')}) {dayOffDetail?.begin_time && (dayOffDetail?.begin_time + ' ~ ' + dayOffDetail?.end_time)}</div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                        {workflow?.type === 'document_request' && (<div className="item">
                                            <div className="label">문서 형태</div>
                                            <div className="contents">{workflow?.document_type}</div>
                                        </div>)}
                                        <div className="memo">{workflow?.memo}</div>
                                    </div>
                                </div>
                                <div className="cardButton">
                                    {workflow?.status === 0 ? (<>
                                        <button className="col2 button" onClick={() => {setModal(true); setModalStatus(1); setModalType(workflow?.type); setModalId(workflow?.id); }}>승인</button>
                                        <button className="col2 button" onClick={() => {setModal(true); setModalStatus(2); setModalType(workflow?.type); setModalId(workflow?.id);}}>반려</button>
                                    </>) : (<>
                                        <div className={`button ${workflow?.status === 2 ? 'danger' : 'complete'}`}>{workflow?.status === 1 ? '승인됨' : (workflow?.status === 2 ? '반려됨' : '')}</div>
                                    </>)}
                                </div>
                            </>
                        ))}
                    </div>
                </PageBody>
                <BottomPopup
                    open={modal}
                    contents={
                        <ContentsSection addClass="btnContents p-0">
                            <InputSection addClass="registerSection mt-20">
                                <div className="pageSubHeaderTitle">{getType(modalType)}을 {modalStatus === 1 ? '승인' : '반려'}처리 하시겠습니까?</div>
                                <div className="pageSubHeaderSubTitle">직원에게 전달할 메시지를 입력해주세요.</div>
                                <TextAreaItem
                                    inputTitle=""
                                    inputName=""
                                    placeholder="메시지 입력 (선택)"
                                    max={500}
                                    maxChk={false}
                                    value={modalMemo}
                                    func={(value) => setModalMemo(value)}
                                />
                            </InputSection>
                        </ContentsSection>
                    }
                    btn1Text="취소"
                    btn1Func={() => { setModal(false) }}
                    btn2Text={modalStatus === 1 ? '승인하기' : '반려하기'}
                    btn2Func={() => { handleSubmit() }}
                />
                <BottomErrMsg
                    text={bottomMsgData.text ? bottomMsgData.text : ""}
                    chk={bottomMsgData.chk}
                />
            </ContentsSection>
        </>
    );
};

function getType(type)
{
    if (type === 'work_modify') {
        return '시간변경 요청';
    } else if (type === 'additional_pay_request') {
        return '추가수당 신청';
    } else if (type === 'day_off_request') {
        return '휴가신청';
    } else if (type === 'document_request') {
        return '문서발급 요청';
    }
}

export default TempWorker;