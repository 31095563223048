import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BottomErrMsg, Popup } from "component/basic/popup";
import { ContentsSection, InputSection, PageTitle } from "component/app/items";
import { InputItemBox } from "component/basic/formItems";
import { BtnItem } from "component/basic/btns";
import { CompanyBtn, SnsBtn } from "component/member/items";
import { Header } from "component/elements/header";
import { NotItems } from "component/basic/notItems";

const Company = (props) => {
    const navigate = useNavigate();

    const companyApi = useGet({
        url:"/company",
        loginType:"login"
    });
    const companyData = companyApi?.data?.companies;

    return (
        <>
            <Header
                pageBack={true}
                func={() => {
                    window.logOut();
                    navigate(`/member/login`);
                }}
                headTitle="근무지 선택"
                centerTitle={true}
            />
            <ContentsSection addClass="headerContents">
                <PageTitle
                    contents="웨이브원 접속을 위해<br/>근무지를 선택해주세요"
                />
                <InputSection>
                    {companyData && companyData?.length > 0 ? 
                        <>
                            {companyData?.map((item,i)=>(
                                <CompanyBtn
                                    state={item.status == 0 ? "rTextColor rBg" : ""}
                                    stateText={item.status == 0 ? "근무중" : "-"}
                                    text={item.name}
                                    disabled={false}
                                    func={()=>{
                                        localStorage.setItem("companyId",item.id);
                                        navigate("/workHistory");
                                    }}
                                />
                            ))}
                        </>
                        :
                        <NotItems addClass="fullPage" text="선택 가능한 근무지가 없습니다."/>
                    }
                </InputSection>
            </ContentsSection>
        </>
    );
};

export default Company;