import { StateItem } from "component/app/subItem";
import { useNavigate } from "react-router-dom";

function SnsBtn(data){
    let navigate = useNavigate();

    return (
        <button type="button" id={data.id||""} className={`snsBtn ${data.addClass ? data.addClass : ""} ${data.img ? data.img : ""}`} onClick={() => data.func()} disabled={data.disabled ? data.disabled : false}>
            <img src={`/assets/images/sns/${data.img}.svg`}/>
            {data.text ? data.text : ""}
        </button>
    );
}

function CompanyBtn(data){
    return (
        <button type="button" id={data.id||""} className={`companyBtn ${data.addClass ? data.addClass : ""}`} onClick={() => data.func()} disabled={data.disabled ? data.disabled : false}>
            <div className="companyBtnTextBox">
                <StateItem addClass={data.state} text={data.stateText}/>
                <h2 className="companyBtnText" dangerouslySetInnerHTML={{__html:data.text}}/>
            </div>
            <img src={`/assets/images/basic/move.svg`}/>
        </button>
    );
}


export {SnsBtn,CompanyBtn};